import { Component } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kiwiz-web-app';



  constructor(
    private _translate: TranslateService,
    private _router: Router,
    private _gtmService: GoogleTagManagerService
  ) {
    this._handleTranslation();
    //this._handleGtmEvents();
  }

  ngOnInit() {
    this.setUpAnalytics();
  }

  /**
   * Sets default language to what the current browser language is.
   * If the translation buttons are pressed, it sets to which ever language is picked.
   */
  private _handleTranslation() {
    let userLang = localStorage.getItem('lang');

    if (userLang == undefined) {
      const navigatorLanguage = navigator.language;
      userLang = (navigatorLanguage.includes('en')) ? 'en' : 'fr';
      localStorage.setItem('lang', userLang);
      this._translate.use(userLang);
    } else {
      this._translate.use(userLang);
    }


  }

  //GoogleAnalytics Set up
  setUpAnalytics() {
    this._router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-9W85781GN0',
          {
            page_path: event.urlAfterRedirects
          }
        );
      }
    });
  }

  private _handleGtmEvents() {
    this._router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this._gtmService.pushTag(gtmTag);
      }
    });
  }
}
