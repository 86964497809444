<ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="close">
        <div class="actions -center" align="center">
            <button class="o-button -primary" mat-dialog-close>
                {{ 'general.letsgo' | translate }}</button>
        </div>
    </ng-container>

</ng-container>
<ng-template #loading>
    <app-loading-secondary></app-loading-secondary>
</ng-template>
