<div class="cancelButton">
    <button mat-icon-button [mat-dialog-close] (click)="this._closeModal(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="modal-head-title">
    <h2 class="title"> {{ data.title }} </h2>
</div>


<div class="content">
    <div class="message" [innerHtml]="data.message"></div>
</div>

<div class="-center" style="margin-top:10px;">
    <button class="-secondary" (click)="this._closeModal(true)" mat-dialog-close>
        {{ data.buttonText}}
    </button>
    <button class="-secondary" (click)="this._closeModal(false)" mat-dialog-close>
        {{ 'general.no' | translate }}
    </button>

























</div>