import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService implements CanActivate {

  constructor(private auth: AngularFireAuth) { }

  async canActivate(): Promise<boolean> {
    return true;
  }
}
