import { Injectable } from '@angular/core';
import { Inspection } from '../entities/inspections/inspection';

@Injectable({
	providedIn: 'root'
})
export class InspectionDisplayLogicService {
	
	public tireWearMessageDisplayed(wearValue: number, type: string) {
		switch (type) {
			case 'Hiver':
			case 'Winter':
			return wearValue <= 5 ? 'replacementRequired' : wearValue <= 7 ? 'replacementSoon' : 'nothingToPlan';

			case 'Été':
			case 'Summer':
			case '4 saisons':
			case '4 seasons':
			return wearValue <= 4 ? 'replacementRequired' : wearValue <= 6 ? 'replacementSoon' : 'nothingToPlan';

			default:
			return '';
		}
	}
	
	public isTireTooOld(field: string, inspection?: Inspection) {
		if (field == undefined || field == null) {
			return false;
		}

		let year: string | undefined = new Date().getFullYear().toString();
		switch (field) {
			case 'driverBack':
			year = inspection?.data?.['driverBackTireYear'];
			break;
			case 'driverFront':
			let goodField = inspection?.data?.sameYearDriverFrontTire ? 'sameYearDriverFrontTire' : 'sameYearFrontTire';
			year = ['Oui', 'Yes'].includes(inspection?.data?.[goodField]) ? inspection?.data?.driverBackTireYear : inspection?.data?.differentYearDriverFrontTire;
			break;
			case 'passengerFront':
			if (inspection?.data?.sameYearPassengerFrontTire)
				year = ['Oui', 'Yes'].includes(inspection?.data?.sameYearPassengerFrontTire) ? inspection?.data?.['driverBackTireYear'] : inspection?.data?.['differentYearPassengerFrontTire'];
			break;
			case 'passengerBack':
			if (inspection?.data?.sameYearPassengerBackTire)
				year = ['Oui', 'Yes'].includes(inspection?.data?.sameYearPassengerBackTire) ? inspection?.data?.['driverBackTireYear'] : inspection?.data?.['differentYearPassengerBackTire'];
			break;
		}

		// To prevent parseInt undefined error
		if (!year) return false;

		let tireAge = new Date(Date.now()).getFullYear() - parseInt(year);

		if (tireAge >= 7) {
			return true;
		} else {
			return false;
		}
	}
  }
  