import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Vehicle } from '../entities/vehicle';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  clientVehicleUrl = environment.apiUrl + '/client/vehicles/';
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,  // Not HttpClient to bypass interceptor
  ) {
    this.httpClient = new HttpClient(handler);
  }

  /**
   * Returns all vehicles from the database.
   * @returns A promise of all the vehicles from the database.
   */
  async getVehicles(
    sort?: string,
    order?: string,
    page: number = 1,
    limit: number = 0,
    search?: string
  ): Promise<Vehicle[]> {
    // return this.httpGet<Vehicle[]>(`${this.clientVehicleUrl}?page=${page}&limit=${limit}&sort=${sort}&order=${order}&search=${search}`);
    return this.httpGet<Vehicle[]>(this.clientVehicleUrl);
    // return new Page<Vehicle[]>(results['itemCount'], results['pages'], results['vehicles']);
  }

  /**
   * Returns the vehicle corresponding to the provided identifier.
   * @param vehicleId The identifier of the vehicule to get.
   * @returns A promise of the vehicle corresponding to the provided identifier.
   */
  async getVehicle(vehicleId: string): Promise<Vehicle> {
    return this.httpGet<Vehicle>(this.clientVehicleUrl + `${vehicleId}`);
  }

  /**
   * Returns the recommended vehicles.
   * @returns A promise of the recommended vehicles.
   */
  async getVehicleSuggestions(): Promise<string[]> {
    return this.httpGet<string[]>(`${this.clientVehicleUrl}suggestions`);
  }

  /**
   * Makes a get call to the provided url and returns a promise containing its response.
   * @param url The url to get.
   * @returns A promise containing the response of the get call.
   */
  private async httpGet<T>(url: string): Promise<T> {
    // const token = await this.auth.token();
    return firstValueFrom(this.httpClient.get<T>(url, {
      headers: {
        // 'auth-token': token,
        // 'x-auth-token': environment.application_token.toString(),
        enctype: 'multipart/form-data',
      }
    })).catch(
      (error) => {
        throw new Error(error);
    });
  }
}
