import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const MAXIMUM_FAVORITES_LENGTH = 3;

export enum FavoritesAddError {
  tooManyCars,
  alreadyFavorite
}

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  favorites$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  dislikes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor() {
    const favorites = localStorage.getItem('favorites');
    if (favorites) {
      this.favorites$.next(JSON.parse(favorites));
    }
  }

  async addFavorite(vehicleId: string): Promise<FavoritesAddError | true> {
    if (this.favorites$.value && this.favorites$.value.length >= MAXIMUM_FAVORITES_LENGTH) {
      return FavoritesAddError.tooManyCars;
    } else if (this.favorites$.value.includes(vehicleId)) {
      return FavoritesAddError.alreadyFavorite;
    }

    this.favorites$.next([...this.favorites$.value, (vehicleId)]);
    this.saveFavorites();
    return true;
  }

  async removeFavorite(vehicleId: string): Promise<boolean> {
    if (this.favorites$ && this.favorites$.value.includes(vehicleId)) {
      this.favorites$.next(this.favorites$.value.slice().filter(value => value !== vehicleId));
      this.saveFavorites();
      return true;
    }

    return false;
  }

  async saveFavorites() {
    localStorage.setItem('favorites', JSON.stringify(this.favorites$.getValue()));
  }

  isVehicleFavorite(vehicleId: string): boolean {
    return this.favorites$.value.includes(vehicleId);
  }

  isVehicleDisliked(vehicleId: string): boolean {
    return this.dislikes$.value.includes(vehicleId);
  }
}
