import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { LoadingSecondaryComponent } from 'src/app/shared/components/loading/loading-secondary/loading-secondary.component';

const components = [
  LoadingComponent,
  LoadingSecondaryComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  exports: components
})
export class LoadingModule { }
