import { Component, Input } from '@angular/core';
import { User } from 'src/app/entities/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent {

  @Input() user!: User;
  /** Sets if color is primary or tertiary */
  @Input() isPrimaryColor = false;

  constructor(private authService: AuthService) { }

  getUserInitials(user: User) : string {
    if (user == null) return "";
    if (user.displayName == null) return "--";
    var initials = "";
    var splittedName = user.displayName.split(" ");
    initials += splittedName[0][0];
    initials += (splittedName[1] != null && splittedName[1] != "") ? splittedName[1][0] : "";
    return initials.toUpperCase();
  }

  getColor() {
    const docStyle = getComputedStyle(document.documentElement);
    return docStyle.getPropertyValue('--'.concat(this.isPrimaryColor ? 'primary' : 'tertiary'));
  }

  disconnectUser() {
    this.authService.disconnect();
  }

}
