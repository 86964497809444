import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private authService: AuthService, private auth: AngularFireAuth) {
  }
  
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
      this.auth.setPersistence('local');
  
      const result = await this.auth.getRedirectResult();
      if (result.credential && result.user) {
        const idToken = await result.user.getIdToken();
        const originParam = localStorage.getItem("origin");
        await this.authService.login(idToken, originParam);
      }

      return true;
  }
  
}
