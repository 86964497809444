import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-code-sent',
  templateUrl: './new-code-sent.component.html',
  styleUrls: ['./new-code-sent.component.scss']
})
export class NewCodeSentComponent implements OnInit {

  email!: string;
  message!: string;

  constructor(public translate:TranslateService) { }

  ngOnInit() {
  }

}
