import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-finished-modal',
  templateUrl: './form-finished-modal.component.html',
  styleUrls: ['./form-finished-modal.component.scss']
})
export class FormFinishedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, title: string, buttonText: string },
    public dialogRef: MatDialogRef<FormFinishedModalComponent>) { }

  ngOnInit() {
  }

  _closeModal(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }

}
