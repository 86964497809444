import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CustomFile } from 'src/app/entities/custom_file';
import { FileService } from 'src/app/services/file.service';
import heic2any from 'heic2any';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() autoUpload: boolean = true;
  @Input() type!: string;
  @Input() instance!: Component;
  @Input() extra: any;
  @Input() showFile: boolean = false;
  @Input() unlimitedFiles: boolean = false;
  @Input() multipleFiles: boolean = false;
  @Input() role: String = 'inspector';

  @Output() onUploaded = new EventEmitter<any>();

  @Input() file!: CustomFile;
  uploading: boolean = false;

  constructor(
    private fileService: FileService,
    private imageCompress: NgxImageCompressService,
  ) { }

  async compressAndUploadFile(event: any) {
    event = event.target.files;
    for (let file of event) {
      var element = file;

      if ((this.type != null && element.type.toString() == this.type) || this.type == null) {

        // Transform heic to jpg first if needed
        if (element.type === 'image/heic') {
            // get image as blob url
            let blobURL = URL.createObjectURL(element);
            
            // convert "fetch" the new blob url
            let blobRes = await fetch(blobURL)

            // convert response to blob
            let blob = await blobRes.blob()

            // convert to PNG - response is blob
            let conversionResult = await heic2any({ blob: blob, toType: "image/jpeg" })

            console.log(conversionResult)
            element = conversionResult
        }

        // Pour la compression, on doit d'abord transformer l'image en format base64
        var reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onloadend = () => {
          var base64data = reader.result;
          
          // Compression de l'image
          this.imageCompress.compressFile(
            base64data?.toString() ?? '',
            0,
            50, // ratio
            75,  // quality
            0, // max-width
            0  // max-height
          ).then((compressedImage: string) => {
            // Transformation de l'image réduite en fichier pour pouvoir téléverser sur Firebase
            fetch(compressedImage)
            .then(res => res.blob())
            .then(blob => {
              let finalFile = new File([blob], file.name, { type: file.type });
              let tempFile = new CustomFile(finalFile);
              this.file = tempFile;

              if (this.autoUpload) {
                this.upload(tempFile);
              }
            });
          }); 
        }
      }
    }
  }


  async upload(file: CustomFile) {
    this.uploading = true;
    var uuid = await this.fileService.uploadFile(file, this.role);
    this.file = await this.fileService.getFile(uuid);
    this.uploading = false;
    this.onUploaded.emit(this.file);
  }
}
