<div class="modal-head">
  <div class="modal-head-title">
    <h2 class="u-h5">{{ data.title }}</h2>
  </div>
</div>

<div class="content">
  <div class="message" [innerHtml]="data.message"></div>
</div>

<div class="-center">
  <button class="-secondary" mat-dialog-close>
    {{ 'general.ok' | translate }}</button>
</div>