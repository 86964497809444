<div class="cancelButton">
    <button mat-icon-button [mat-dialog-close] (click)="this._closeModal()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="modal-head-title">
    <h2 class="title"> {{ 'client.inspections.mandate_page.newInspectionTypeModal.title' | translate }} </h2>
</div>


<!-- <div class="content">
    <div class="message" [innerHtml]="data.message"></div>
</div> -->

<div class="content -center" style="margin-top:10px;">
    <button class="-secondary" (click)="this._closeModal('complete')" mat-dialog-close>
        {{ 'client.inspections.mandate_page.newInspectionTypeModal.complete' | translate }}
    </button>
    <button class="-secondary" (click)="this._closeModal('iA')" mat-dialog-close>
        {{ 'client.inspections.mandate_page.newInspectionTypeModal.iA' | translate }}
    </button>
</div>