<div class="menu">
    <div class="logo" >
        <img src="assets/logo_yellow.svg" routerLink="/client/home">
    </div>

    <section class="user" *ngIf="showUser && user">
        <img class="picture" *ngIf="!user.displayName || user.displayName == ''; else image"
            src="{{ (user.photoURL) ? user.photoURL : '/assets/icon.jpeg' }}" alt="">
        <ng-template #image>
            <app-user-image [user]="user"></app-user-image>
        </ng-template>
        <p>{{ user.displayName }}</p>
    </section>

    <ng-content></ng-content>

    <hr>
    <app-help-button></app-help-button>
    <app-translate-button></app-translate-button>
    <button class="disconnect" (click)="disconnectUser()">
        {{ 'general.disconnect' | translate }}
    </button>
</div>