import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-translate-button',
  templateUrl: './translate-button.component.html',
  styleUrls: ['./translate-button.component.scss']
})
export class TranslateButtonComponent implements OnInit {

  currentLang$ = new BehaviorSubject<string>(localStorage.getItem('lang') ?? 'fr');
  @Input() color: string | undefined;

  constructor(
    private translateService: TranslateService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.refreshBtnColors();
  }

  translateInFrench(event: MouseEvent) {
    event.stopPropagation();
    if (localStorage.getItem('lang') === 'fr') {
      return;
    }

    this.translateService.use('fr');
    localStorage.setItem('lang', 'fr');
    this.refreshBtnColors();
    window.location.reload();
  }

  translateInEnglish(event: MouseEvent) {
    event.stopPropagation();
    if (localStorage.getItem('lang') === 'en') {
      return;
    }

    this.translateService.use('en');
    localStorage.setItem('lang', 'en');
    this.refreshBtnColors();
    window.location.reload();
  }

  refreshBtnColors() {
    this.currentLang$.next(localStorage.getItem('lang') ?? 'fr');
  }
}
