import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kiwi-rating',
  templateUrl: './kiwi-rating.component.html',
  styleUrls: ['./kiwi-rating.component.scss']
})
export class KiwiRatingComponent implements OnInit {

  @Input() rating! : number;

  constructor() { }

  ngOnInit() {
  }

}
