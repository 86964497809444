import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  CompleteInspection,
  InspectionData,
} from 'src/app/entities/inspections/complete-inspection';
import { Inspection } from 'src/app/entities/inspections/inspection';
import { Inspector } from 'src/app/entities/inspector';
import { Purchase } from 'src/app/entities/purchase';
import { InspectionService } from 'src/app/services/inspection.service';
import { PurchaseService } from 'src/app/services/purchase.service';
interface MarketValues {
  data: {
    make: string;
    market_price_pro: number;
    market_price_individual: number;
    time_to_sell: number;
    country_count: number;
    model: string;
    avg_dist_perYear: number;
    avg_price: number;
    year: number;
  };
  price: number;
}

/**
 * Represents a field that needs reparation.
 */
export interface Repair {
  /** The part that needs reparation. */
  part: string;
  /** The comment left by the inspector. */
  comment?: string[];
  /** The repair cost of that part. */
  cost: number;
  /** Recommandation left by admin after the inspection */
  recommandations: string | string[];
}

@Component({
  selector: 'app-inspection-synthesis',
  templateUrl: './inspection-synthesis.component.html',
  styleUrls: ['./inspection-synthesis.component.scss'],
})
export class InspectionSynthesisComponent implements OnInit {
  /** The current inspection. */
  @Input() inspection$ = new BehaviorSubject<CompleteInspection | null>(null);

  /** The current purchase. */
  @Input() purchase$ = new BehaviorSubject<Purchase | null>(null);

  /** To pass an existing inspection instead of querying from server. */
  @Input() inspection!: CompleteInspection;

  // Elements to print to pdf
  @ViewChild('top') top!: ElementRef;
  @ViewChild('middle') middle!: ElementRef;
  @ViewChild('bottom') bottom!: ElementRef;

  /** The required repairs. */
  mandatoryRepairs$ = new BehaviorSubject<Repair[]>([]);
  /** The total cost for mandatory repairs. */
  mandatoryCost$ = this.mandatoryRepairs$.pipe(
    switchMap((repairs) => {
      let sum = 0;
      repairs.forEach((repair) => {
        if (repair.cost) sum += repair.cost;
      });
      return of(sum);
    })
  );

  /** The recommended repairs. */
  recommendedRepairs$ = new BehaviorSubject<Repair[]>([]);
  /** The total cost for recommended repairs. */
  recommendedCost$ = this.recommendedRepairs$.pipe(
    switchMap((repairs) => {
      let sum = 0;
      repairs.forEach((repair) => {
        if (repair.cost) sum += repair.cost;
      });
      return of(sum);
    })
  );

  inspector$ = new BehaviorSubject<Inspector | null>(null);
  language: string | null;
  firstPhotoSrc$ = new BehaviorSubject<string | null>(null);
  marketValue$ = new BehaviorSubject<MarketValues | null>(null);

  /** Fields that have more than one entry but only need to be checked once. */
  frontBrakeDamage = false;
  backBrakeDamage = false;
  frontTireUsed = false;
  backTireUsed = false;

  constructor(
    private inspectionService: InspectionService,
    private translate: TranslateService,
    private purchaseService: PurchaseService
  ) {
    this.language = localStorage.getItem('lang');
  }

  async ngOnInit() {
  
    this.inspection$
      .pipe(
        tap(async (inspection) => {
          if (!inspection || !inspection.data) return;
          let purchase = await this.purchaseService.getPurchaseWithInspectionId(
            inspection._id
          );
          this.purchase$.next(purchase);
          //getMarketValue
          if (purchase!.data.marketValue) {
            let marketValue = await this.inspectionService.getMarketValue(
              inspection.data?.make.trim() || '',
              inspection.data?.model.trim() || '',
              inspection.data?.mileage.trim() || '',
              inspection.data?.year.trim() || '',
              inspection._id || ''
            );

            this.marketValue$.next(marketValue as unknown as MarketValues);
          }

          inspection.data.photos.normal_photos.forEach((photo) => {
            if (photo[2] == 'carThreeQuarterFrontDriver') {
              this.firstPhotoSrc$.next(photo[0]);
            }
          });
          this.getInspectorInfo(inspection.inspector_id);

          const toFixArray: Repair[] = [];
          const potentialFixArray: Repair[] = [];

          Object.keys(inspection.data).forEach((field) => {
            if (
              inspection.data?.[field] &&
              inspection.data[field][0] &&
              field != 'roadTestInfo' &&
              !this.excludedFields.includes(field)
            ) {
              const partCondition = inspection.data[field][0];
              const fieldLength = inspection.data[field].length;
              let recommandations = inspection.data.recommandations[field];
              let cost = inspection.data.cost[field];
              // const cost = inspection.data[field][fieldLength - 1];

              let comments: string[];

              if (fieldLength > 1) {
                comments = inspection.data[field].slice(1, fieldLength);

                if (
                  comments &&
                  (comments.includes('Autre') || comments.includes('Anomalie'))
                ) {
                  comments.includes('Autre')
                    ? comments.splice(comments.indexOf('Autre'), 1)
                    : comments.splice(comments.indexOf('Anomalie'), 1);
                }
                if (
                  comments &&
                  (comments.includes('Other') || comments.includes('Anomaly'))
                ) {
                  comments.includes('Other')
                    ? comments.splice(comments.indexOf('Other'), 1)
                    : comments.splice(comments.indexOf('Anomaly'), 1);
                }
              } else {
                comments = [
                  ...inspection.data[field],
                  inspection.data[field + 'ConditionOther'],
                ].filter((comment) => comment != undefined);
              }

              if (field.toLowerCase().includes('tirewear')) {
                let tireYear = this.getTireYear(field, inspection.data);
                if (tireYear)
                  this.checkTireWear(
                    field,
                    inspection.data[field],
                    inspection.data,
                    toFixArray,
                    parseInt(tireYear)
                  );
              }

              if (
                this.autoRecommandationElements.includes(field) &&
                (recommandations == undefined || recommandations == null)
              ) {
                let autoRecommandations = this.getAutoRecommandations(
                  field,
                  inspection
                );

                if (autoRecommandations != -1) {
                  const rejectedIndexes = [
                    comments.indexOf('Autre'),
                    comments.indexOf('Other'),
                    comments.indexOf('Mauvais'),
                  ];
                  recommandations = autoRecommandations['recommandation'];
                  cost = autoRecommandations['cost'];
                  comments = autoRecommandations['comment']
                    ? autoRecommandations['comment']
                    : Array.isArray(comments)
                    ? comments.splice(Math.max(...rejectedIndexes) + 1)
                    : comments;
                }
              }

              if (
                (comments != undefined || recommandations != undefined) &&
                cost != undefined
              ) {
                let shouldBeAdded = true;
                // Special case with the brakes where we had multiple recommendations at once
                if (field.toLowerCase().includes('brake')) {
                  // The new recommendation is related to brakes
                  let existingBrakeRepairs = toFixArray.filter(
                    (repair) =>
                      repair.part.toLowerCase().includes('frontBrake') ||
                      repair.part.toLowerCase().includes('backBrake')
                  );

                  // Recommandation should not be an array, we convert it to a string if it is
                  recommandations = recommandations.toString();

                  if (
                    field.toLowerCase().includes('front') ||
                    field.toLowerCase().includes('back')
                  ) {
                    let frontOrBack = field.toLowerCase().includes('front')
                      ? 'front'
                      : 'back';
                    let existingBrakeRepairs = toFixArray.filter((repair) =>
                      repair.part.toLowerCase().includes(frontOrBack)
                    );
                    if (
                      recommandations.toLowerCase().includes('remplacement') ||
                      recommandations.toLowerCase().includes('replacement') ||
                      existingBrakeRepairs.length === 0
                    ) {
                      for (let existingRepair of existingBrakeRepairs) {
                        toFixArray.splice(
                          toFixArray.indexOf(existingRepair),
                          1
                        );
                      }
                    } else {
                      shouldBeAdded = false;
                      for (let existingRepair of existingBrakeRepairs) {
                        let existingRepairRecommandations =
                          existingRepair.recommandations.toString();
                        if (
                          existingRepairRecommandations
                            .toLowerCase()
                            .includes('maintenance')
                        ) {
                          toFixArray.splice(
                            toFixArray.indexOf(existingRepair),
                            1
                          );
                          shouldBeAdded = true;
                        }
                      }
                    }
                  } else if (existingBrakeRepairs.length > 0) {
                    // We give priority to frontBrake and backBrake fields. If they are present, we don't add the brakeNoice related repair
                    shouldBeAdded = false;
                  }
                }

                if (shouldBeAdded) {
                  toFixArray.push({
                    part: field,
                    comment: comments,
                    recommandations,
                    cost,
                  });
                }
              }
            } else if (!this.excludedFields.includes(field)) {
              let recommandations, cost;

              if (this.autoRecommandationElements.includes(field)) {
                let autoRecommandations = this.getAutoRecommandations(
                  field,
                  inspection
                );

                if (autoRecommandations != -1) {
                  recommandations = autoRecommandations['recommandation'];
                  cost = autoRecommandations['cost'];

                  //to avoid to push the brakeField repair twice
                  if (
                    !(
                      field.includes('BackBrake') &&
                      recommandations == undefined
                    ) &&
                    !(
                      field.includes('FrontBrake') &&
                      recommandations == undefined
                    ) &&
                    (inspection.data?.[field] != undefined ||
                      recommandations != undefined) &&
                    cost != undefined
                  ) {
                    toFixArray.push({
                      // no verification before pushing : if the field is in the autoRecommandationElements, we consider that it's good
                      part: field,
                      comment: inspection.data?.[field],
                      recommandations,
                      cost,
                    });
                  }
                }
              }
            }
          });

          // fix cargoCover comment and removes every item that costs 0
          toFixArray.forEach((element) => {
            if (element.part === 'cargoCover') {
              element.comment = [inspection.data?.['cargoCover'] || 'no'];
            }
            if (element.cost === 0) {
              let index = toFixArray.indexOf(element);
              toFixArray.splice(index, 1);
            }
          });

          this.mandatoryRepairs$.next(toFixArray);

          // this.recommendedRepairs$.next(potentialFixArray);
        })
      )
      .subscribe();

    if (this.inspection) {
      let uid = this.inspection._id;
      let pwd = this.inspection.password;

      if (uid && pwd) {
        this.inspection$.next(
          await this.inspectionService.getOrganizedInspection(uid, pwd)
        );
        this.getInspectorInfo(this.inspection$.value?.inspector_id);
      }
    }
    this.language = localStorage.getItem('lang');
  }

  getInspectorInfo(inspector_id?: string) {
    if (!inspector_id) return;

    this.inspectionService
      .getInspectorInfo(inspector_id)
      .then((inspector) => {
        this.inspector$.next(inspector);
      })
      .catch((e) => this.inspector$.next(null));
  }

  checkTireWear(
    field: string,
    value: number,
    data: InspectionData,
    toFixArray: Repair[],
    year: number
  ) {
    const currentYear = new Date(Date.now()).getFullYear();
    let tireAge = currentYear - year;

    if (
      !field.toLowerCase().includes('tireweartire') &&
      ((['Hiver', 'Winter'].includes(data.typeDriverBackTire) && value <= 5) ||
        (['Été', 'Summer'].includes(data.typeDriverBackTire) && value <= 4) ||
        tireAge >= 10)
    ) {
      let recommendation, estimatedCost;
      if (
        ['4 roues motrices', '4 wheel drive'].includes(
          data.vehicleMotricityType
        ) &&
        this.frontTireUsed == false &&
        this.backTireUsed == false
      ) {
        this.frontTireUsed = true;
        this.backTireUsed = true;
        field = 'tires';
        recommendation =
          this.language == 'fr'
            ? 'Remplacement requis des 4 pneus'
            : '4 Tires replacement required';
        estimatedCost = 800;

        toFixArray.push({
          part: field,
          comment: ['Usé(s)'],
          recommandations: recommendation,
          cost: estimatedCost, //data.cost[field],
        });
      } else {
        let nbPneus = 2;
        if (
          field.toLowerCase().includes('front') &&
          this.frontTireUsed == false
        ) {
          this.frontTireUsed = true;
          field = 'Front tires';
        } else if (
          field.toLowerCase().includes('back') &&
          this.backTireUsed == false
        ) {
          this.backTireUsed = true;
          field = 'Rear tires';
          if (
            this.inspection$.value?.data &&
            ['6 roues', '6 wheels'].includes(
              this.inspection$.value.data.numberOfTires
            )
          )
            nbPneus = 4;
        }
        estimatedCost = 200 * nbPneus;

        if (field == 'Front tires' || field == 'Rear tires') {
          const otherField =
            field === 'Front tires' ? 'Rear tires' : 'Front tires';

          let otherValue = toFixArray.find(
            (element) => element.part === otherField
          );
          if (otherValue) {
            // All tires need to be changed
            toFixArray.splice(toFixArray.indexOf(otherValue));
            estimatedCost += otherValue.cost;
            if (otherValue.recommandations.includes('2')) {
              nbPneus += 2;
            } else {
              nbPneus += 4;
            }
            field = 'Tires';
          }

          recommendation =
            this.language == 'fr'
              ? 'Remplacement requis des ' + nbPneus + ' pneus'
              : nbPneus + ' Tires replacement required';

          toFixArray.push({
            part: field,
            comment: ['Usé(s)'],
            recommandations: recommendation,
            cost: estimatedCost, //data.cost[field],
          });
        }
      }
    }
  }

  getTireYear(field: string, data: InspectionData): string | undefined {
    let year;
    switch (field) {
      case 'driverBackTireWear':
        year = data['driverBackTireYear'];
        break;
      case 'driverFrontTireWear':
        let goodField = data['sameYearDriverFrontTire']
          ? 'sameYearDriverFrontTire'
          : 'sameYearFrontTire';
        year =
          data[goodField] == 'Oui' || data[goodField] == 'Yes'
            ? data['driverBackTireYear']
            : data['differentYearDriverFrontTire'];
        break;
      case 'passengerFrontTireWear':
        year =
          data['sameYearPassengerFrontTire'] == 'Oui' ||
          data['sameYearPassengerFrontTire'] == 'Yes'
            ? data['driverBackTireYear']
            : data['differentYearPassengerFrontTire'];
        break;
      case 'passengerBackTireWear':
        year =
          data['sameYearPassengerBackTire'] == 'Oui' ||
          data['sameYearPassengerBackTire'] == 'Yes'
            ? data['driverBackTireYear']
            : data['differentYearPassengerBackTire'];
        break;
    }
    return year;
  }

  // Check if the inspection was made before 08-3-2023 to apply the old recommendation system
  oldInspection(date: number) {
    if (date < 1678396463743) {
      return true;
    } else {
      return false;
    }
  }

  getHTMLSectionsClones() {
    return [
      this.top?.nativeElement.cloneNode(true),
      this.middle?.nativeElement.cloneNode(true),
      this.bottom?.nativeElement.cloneNode(true),
    ].filter((e) => !!e);
  }

  getAutoRecommandations(
    field: string,
    inspection: Inspection
  ):
    | -1
    | {
        recommandation: string | string[];
        cost: number;
        comment: string[] | null;
      } {
    if (
      !inspection ||
      !inspection.data ||
      !inspection.data[field] ||
      inspection.data[field].length === 0 ||
      inspection.data[field][0] === 'Excellent' ||
      inspection.data[field][0] === null
    )
      return -1;

    let recommandation: string | [string, {}] | null = null;
    let cost: number | null = 0;
    let comment: string[] | null = null;

    switch (field) {
      case 'driverBackBrake':
      case 'driverFrontBrake':
      case 'passengerBackBrake':
      case 'passengerFrontBrake':
        if (
          (this.backBrakeDamage && field.includes('Back')) ||
          (this.frontBrakeDamage && field.includes('Front'))
        )
          break;

        const position = field.includes('Back') ? 'Back' : 'Front';

        // To avoid having front or back twice
        if (position === 'Back') this.backBrakeDamage = true;
        else this.frontBrakeDamage = true;

        let driverSideDamage =
          inspection.data['driver' + position + 'Brake'][1];
        let passengerSideDamage =
          inspection.data['passenger' + position + 'Brake'][1];

        for (let damage of [driverSideDamage, passengerSideDamage]) {
          switch (damage) {
            case 'Usé(e)(s)':
            case 'Worn':
            case 'Rouille majeure':
            case 'Major Rust':
              recommandation = 'replacement';
              comment = damage;
              cost = 500;
              break;
            case 'Rouille mineure':
            case 'Minor rust':
              recommandation = recommandation || 'brakeService';
              comment = comment || damage;
              cost = 100;
              break;
          }
        }
        break;

      case 'backWindshieldWiper':
      case 'frontWindshieldWiper':
        switch (inspection.data[field][1]) {
          case 'Balais essuie-glace défectueux':
          case 'Wiper blades':
            recommandation = 'wiperReplacement';
            break;
        }

        if (recommandation) {
          if (field.includes('Back')) {
            cost = 25;
          } else {
            cost = 70;
          }
        }
        break;

      case 'frontWindshield':
      case 'backWindshield':
        switch (inspection.data[field][0]) {
          case 'Impact(s)':
            recommandation = 'repair';
            break;
          case 'Fissuré':
          case 'Cracked':
            recommandation = 'replacementVariableCost';
            break;
        }

        switch (recommandation) {
          case 'repair':
            cost = 100;
            break;
          case 'replacementVariableCost':
            cost = 750;
            break;
        }
        break;

      case 'driverBackOpticalBlock':
      case 'driverFrontOpticalBlock':
      case 'passengerBackOpticalBlock':
      case 'passengerFrontOpticalBlock':
        if (inspection.data[field][1]?.toLowerCase().includes('opaque')) {
          recommandation = 'aestheticTreatment';
          cost = 40;
        }
        break;

      case 'frontFlashingSignalLamps':
      case 'backFlashingSignalLamps':
      case 'brakeLights':
      case 'reversingLamps':
        recommandation = 'replacement';
        cost = 30;
        break;

      case 'frontPassingLamps':
      case 'licensePlateLight':
        recommandation = 'replacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 20;
        break;

      case 'frontMainBeam':
      case 'frontHeadLights':
        recommandation = 'replacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 50;
        break;

      case 'frontFogLights':
        recommandation = 'replacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 50;
        break;

      case 'insideLighting':
      case 'backPositionLamps':
        recommandation = 'replacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 30;
        break;

      case 'battery':
        switch (inspection.data[field][1]) {
          case 'Oxydation':
          case 'Oxidation':
            recommandation = 'batteryPoleMaintenance';
            cost = 40;
            break;
          default:
            recommandation = 'batteryReplacement';
            cost = 230;
            break;
        }
        break;

      case 'exhaustSystem':
        switch (inspection.data[field][1]) {
          case 'Détérioration majeure':
          case 'Major deterioration':
            recommandation = 'specializedDiagnosis';
            cost = 100;
            break;
          case 'Bruit de tôle':
          case 'Heatshield noise':
            recommandation = 'exhaustPlateFix';
            cost = 150;
            break;
          case 'Fuite majeure':
          case 'Major leak':
            recommandation = 'exhaustSystemRepair';
            cost = 300;
            break;
        }
        break;

      case 'powerSteeringFluidLevel':
        switch (inspection.data[field][1]) {
          case 'Insuffisant':
          case 'Too low':
            recommandation = 'powerSteeringFluidReplacement';
            cost = 300;
            break;
          case 'Trop élevé':
          case 'Too high':
            recommandation = 'powerSteeringFluidDrainage';
            cost = 160;
            break;
        }
        break;

      case 'coolantLevel':
        switch (inspection.data[field][1]) {
          case 'Insuffisant':
          case 'Too low':
            recommandation = 'levelAdjustment';
            cost = 15;
            break;
        }
        break;

      case 'accessoryBelt':
        recommandation = 'accessoryBeltReplacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 140;
        break;

      case 'wiperFluidLevel':
        switch (inspection.data[field][1]) {
          case 'Insuffisant':
          case 'Too low':
            recommandation = 'wiperFluidFilling';
            cost = 10;
            break;
        }
        break;

      case 'brakeFluidLevel':
        switch (inspection.data[field][1]) {
          case 'Trop élevé':
          case 'Too high':
            recommandation = 'brakeFluidReplacement';
            cost = 200;
            break;
          case 'Insuffisant':
          case 'Too low':
            switch (inspection.data[field][2]) {
              case 'Majeur':
              case 'Major problem':
                recommandation = 'brakeFluidIntervention';
                break;
              default:
                recommandation = 'brakeFluidOk';
                break;
            }
        }
        break;

      case 'motorOilLevel':
        switch (inspection.data[field][1]) {
          case 'Insuffisant':
          case 'Trop élevé':
          case 'Too low':
          case 'Too high':
            recommandation = 'oilFilterChange';
            cost = 115;
            break;
        }
        break;

      case 'isOBDScanner':
        switch (inspection.data[field][1]) {
          case "Présence d'un ou plusieurs codes":
          case 'One or more error code':
            recommandation = 'specializedDiagnosis';
            cost = 200;
            break;
        }
        break;

      case 'wheelAlignment':
        if (
          inspection.data[field].includes('Mauvais') ||
          inspection.data[field].includes('Bad')
        ) {
          recommandation = 'toFix';
          cost = 100;
        } else {
          comment = ['']; // empty comment to be sure that 'Mauvais' is not displayed
        }
        break;

      case 'rearSuspension':
      case 'frontSuspension':
        let minCost = 150;
        let maxCost = 800;
        cost = 150;

        recommandation = [
          'anomalyRange',
          { minCost: minCost.toString(), maxCost: maxCost.toString() },
        ];
        break;

      case 'goodLineKeeping':
        recommandation = ['anomalyRange', { minCost: '150', maxCost: '1000' }];
        cost = 150;
        break;

      case 'directionProperFunctioning':
        recommandation = ['anomalyRange', { minCost: '300', maxCost: '1000' }];
        cost = 150;
        break;

      case 'brakeNoice':
        comment = [inspection.data[field][0]];
        switch (inspection.data[field][1]) {
          case 'Entretien de frein':
          case 'Brake maintenance':
            recommandation = 'brakeMaintenance';
            cost = 170;
            break;
          case 'Remplacement':
          case 'Replacement':
            const frontBack = inspection.data['brakeNoiceDamageFrontBack'];
            const tireCount = frontBack.length === 2 ? 4 : 2;
            cost = 250 * tireCount;

            let frontBackTranslation = '';
            if (tireCount <= 2) {
              if (['Avant', 'Front'].includes(frontBack)) {
                frontBackTranslation =
                  this.translate.currentLang === 'en' ? 'front' : 'avant';
              } else if (['Arrière', 'Back'].includes(frontBack)) {
                frontBackTranslation =
                  this.translate.currentLang === 'en' ? 'back' : 'arrière';
              }
            }
            recommandation = [
              'brakeReplacement',
              { count: tireCount, frontBack: frontBackTranslation },
            ];
            break;
          case 'Diagnostic':
            recommandation = [
              'anomalyRange',
              { minCost: '150', maxCost: '500' },
            ];
            cost = 150;
            break;
        }
        break;

      case 'constantAccelerationRoadTest':
      case 'normalPressureTempRoadTest':
      case 'engineVibration':
      case 'loudTransmission':
        // Loud transmission is not a problem if the value is no
        if (
          field === 'loudTransmission' &&
          ['Non', 'No'].includes(inspection.data[field][0])
        )
          break;

        recommandation = ['anomalyRange', { minCost: '200', maxCost: '2,000' }];
        cost = 200;
        break;

      case 'smoothGearShift':
      case 'goodClutch':
      case 'loudDifferential':
        // Loud transmission is not a problem if the value is no
        if (
          field === 'loudDifferential' &&
          ['Non', 'No'].includes(inspection.data[field][0])
        )
          break;

        const transmission = inspection.data['vehicleTransmission'];

        let costRange;
        if (transmission === 'Manuelle') {
          costRange = { minCost: '1,000', maxCost: '2,000' };
        } else {
          costRange = { minCost: '4,000', maxCost: '6,000' };
        }
        recommandation = ['anomalyRange', costRange];
        cost = 200;
        break;

      case 'airFilter':
        recommandation = 'airFilterReplacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 70;
        break;

      case 'odor':
        recommandation = 'interiorShampoo';
        if (inspection.data[field][0] === 'Mauvais') cost = 120;
        break;

      case 'airConditioning':
        let costMin = ['No', 'Non'].includes(
          inspection.data['acCompressorStarted']
        )
          ? '600'
          : '300';
        recommandation = [
          'anomalyRange',
          { minCost: costMin, maxCost: '1,500' },
        ];
        if (inspection.data[field][0] === 'Mauvais') cost = 200;
        break;

      case 'carHorn':
        recommandation = ['anomalyRange', { minCost: '150', maxCost: '600' }];
        if (inspection.data[field][0] === 'Mauvais') cost = 150;
        break;

      case 'homeCharger':
        recommandation = 'chargerReplacement';
        if (inspection.data[field][0] === 'Mauvais') cost = 250;
        break;

      case 'electricalConnector':
        recommandation = 'instantDiagnosis';
        if (inspection.data[field][0] === 'Mauvais') cost = 300;
        break;

      case 'cruiseControl':
        recommandation = 'specializedDiagnosis';
        if (inspection.data[field][0] === 'Mauvais') cost = 200;
        break;

      case 'cargoCover':
        recommandation = 'cargoCoverReplacement';
        if (['No', 'Non'].includes(inspection.data[field])) cost = 275;
        break;

      case 'regenBraking':
        recommandation = 'regenBraking';
        cost = 300;
        break;

      case 'motorManagement':
        recommandation = 'motorManagement';
        cost = 300;
        break;

      case 'visibleEngineLeak':
      case 'visibleTransmissionLeak':
      case 'visibleDifferentialLeak':
      case 'brakeSystemLeak':
        if (inspection.data[field][1] === 'Diagnostic') {
          recommandation = 'leakDiagnostic';
          cost = 70;
        } else if (
          inspection.data[field]?.[1]?.includes('Shampoing') ||
          inspection.data[field]?.[1]?.includes('shampoo')
        ) {
          recommandation = 'leakShampooAndDiagnostic';
          cost = 140;
        }
        break;

      case 'visibleShockAbsorbersLeak':
        let frontReplacement =
          inspection.data[field + 'FrontBack']?.[0] === 'Avant' ||
          inspection.data[field + 'FrontBack']?.[0] === 'Front';
        let backReplacement =
          inspection.data[field + 'FrontBack']?.[0] === 'Arrière' ||
          inspection.data[field + 'FrontBack']?.[0] === 'Back';
        let frontBackTranslation = '';
        if (frontReplacement && backReplacement) {
          frontBackTranslation =
            this.translate.currentLang === 'en'
              ? 'Front and back'
              : 'avants et arrières';
          cost = 950;
        } else if (frontReplacement) {
          frontBackTranslation =
            this.translate.currentLang === 'en' ? 'Front' : 'avants';
          cost = 600;
        } else if (backReplacement) {
          frontBackTranslation =
            this.translate.currentLang === 'en' ? 'Back' : 'arrières';
          cost = 350;
        }
        if (cost)
          recommandation = [
            'shockAbsorbersLeak',
            { frontBack: frontBackTranslation },
          ];
        break;
    }

    if (recommandation && recommandation.length === 2) {
      recommandation = this.translate.instant(
        'client.inspections.reports.inspection_details.tab_synthesis.recommendation.' +
          recommandation[0],
        recommandation[1]
      );
    } else if (typeof recommandation === 'string') {
      recommandation = this.translate.instant(
        'client.inspections.reports.inspection_details.tab_synthesis.recommendation.' +
          recommandation
      );
    } else {
      recommandation = '';
    }

    if (
      inspection.data[field][1] &&
      (inspection.data[field][1].includes('Les deux') ||
        inspection.data[field][1].includes('Both'))
    )
      cost *= 2;

    return recommandation && typeof recommandation === 'string'
      ? { recommandation, cost, comment }
      : -1;
  }
  conditionsToRepairs = [
    'Mauvais',
    'Anomalie',
    'Impact(s)',
    'Fissuré',
    'Oxydation',
    'Rouille',
    'Fissure',
    'Déformation',
    "Présence d'acide",
    'Bruit',
    'Noise',
    'Vibration',
    'Dérive',
    'Drift',
    'Autre',
    'Other',
    'Point dur',
    'Hard to turn',
    'Yes',
    'Oui',
  ];

  autoRecommandationElements = [
    'driverBackBrake',
    'driverFrontBrake',
    'passengerBackBrake',
    'passengerFrontBrake',

    // 'driverBackRim',
    // 'driverFrontRim',
    // 'passengerBackRim',
    // 'passengerFrontRim',

    'backWindshieldWiper',
    'frontWindshieldWiper',

    'frontWindshield',
    'backWindshield',

    'driverBackOpticalBlock',
    'driverFrontOpticalBlock',
    'passengerBackOpticalBlock',
    'passengerFrontOpticalBlock',

    'frontFlashingSignalLamps',
    'backFlashingSignalLamps',
    'frontPassingLamps',
    'frontMainBeam',
    'frontHeadLights',
    'frontFogLights',
    'brakeLights',
    'reversingLamps',
    'licensePlateLight',
    'insideLighting',
    'backPositionLamps',

    'battery',
    'exhaustSystem',
    'accessoryBelt',
    'isOBDScanner',
    'wheelAlignment',

    'powerSteeringFluidLevel',
    'coolantLevel',
    'wiperFluidLevel',
    'brakeFluidLevel',
    'motorOilLevel',

    'constantAccelerationRoadTest',
    'normalPressureTempRoadTest',
    'engineVibration',
    'smoothGearShift',
    'goodClutch',
    'goodSuspension', // Obsolete as of 2022-07-07, kept for older version support
    'frontSuspension',
    'rearSuspension',
    'goodLineKeeping',
    'directionProperFunctioning',
    'brakeNoice',

    'airFilter',
    'odor',
    'airConditioning',
    'carHorn',
    'homeCharger',
    'electricalConnector',

    'cruiseControl',
    'cargoCover',

    'visibleEngineLeak',
    'visibleTransmissionLeak',
    'visibleDifferentialLeak',
    'visibleShockAbsorbersLeak',
    'brakeSystemLeak',
    'loudTransmission',
    'loudDifferential',

    // Electrique
    'motorManagement',
    'regenBraking',
  ];

  excludedFields = [
    // Esthetique
    'overallCleanliness',
    // 'odor',
    'headliner',
    'frontSeats',
    'backSeats',
    'carpets',
    'insideTrunk',
    'carPlastics',
    'doorPlastics',
    'dashboardPlastics',
    'centralConsolePlastics',
    'frontBumper',
    'backBumper',
    'frontWindshieldWiperEsthetic',
    'backWindshieldWiperEsthetic',
    // 'driverFrontOpticalBlock',
    // 'driverBackOpticalBlock',
    // 'passengerFrontOpticalBlock',
    // 'passengerBackOpticalBlock',
    'driverMirrorEsthetic',
    'passengerMirrorEsthetic',
    'interiorMirrorEsthetic',
    'driverFrontWing',
    'driverBackWing',
    'passengerFrontWing',
    'passengerBackWing',
    'driverFrontDoor',
    'driverBackDoor',
    'passengerFrontDoor',
    'passengerBackDoor',
    'driverUnderBody',
    'passengerUnderBody',
    'driverFrontRim',
    'driverBackRim',
    'passengerFrontRim',
    'passengerBackRim',
    'hood',
    'trunk',
    'roof',
    'roofOpening',
    'floor',
    'trunkLiner',
    'trunkOpening',
    'paint',
    'rustInhibitor',

    'driverFrontDoorOpening',
    'driverBackDoorOpening',
    'passengerFrontDoorOpening',
    'passengerBackDoorOpening',

    'overallAesthetic',
    'overallMechanical',
    'overallFunctional',
  ];

  //Method that determine the tire's type from a tire field (driverFrontTireWear for example) --> return Hiver or Ete
  //Not used for the moment
  // determineTireType(field: string, data) {
  //   let tireType;
  //   if (field.toLowerCase().includes('driver')) { //check if driver side
  //     if (field.toLowerCase().includes('back')) { // check if it's back or front
  //       tireType = data['typeDriverBackTire'];
  //     }
  //     else {
  //       tireType = (data['sameTypeDriverFrontTire'].toLowerCase() == 'oui' // check if sameType or not
  //         || data['sameTypeDriverFrontTire'].toLowerCase() == 'yes') ?
  //         data['typeDriverBackTire'] : data['differentTypeDriverFrontTire'];
  //     }
  //   } else if (field.toLowerCase().includes('passenger')) { //check if passenger side
  //     if (field.toLowerCase().includes('back')) { // check if it's back or front
  //       tireType = (data['sameTypePassengerBackTire'].toLowerCase() == 'oui' // check if sameType or not
  //         || data['sameTypePassengerBackTire'].toLowerCase() == 'yes') ?
  //         data['typeDriverBackTire'] : data['differentTypePassengerBackTire'];
  //     }
  //     else {
  //       tireType = (data['sameTypePassengerFrontTire'].toLowerCase() == 'oui' // check if sameType or not
  //         || data['sameTypePassengerFrontTire'].toLowerCase() == 'yes') ?
  //         data['typeDriverBackTire'] : data['differentTypePassengerFrontTire '];
  //     }
  //   }

  //   return tireType;
  // }
}
