import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FavoritesService } from 'src/app/services/favorites.service';
import { VehiclesService } from 'src/app/services/vehicles.service';
import { environment } from 'src/environments/environment';
import { Vehicle } from '../../../../entities/vehicle';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit, OnChanges {
  @Input() canRemove = false;

  @Input() set vehicleId(value: string) {
    this.isLoadingStars = true;
    this._vehicleId = value;
    this.loadVehicleById();
  }

  @Input() set vehicle(value: Vehicle) {
    this.isLoadingStars = true;
    this._vehicle = value;
    this.isLoadingStars = false;

    // sorting the year, minYear to maxYear : 2009-2010-2011 and not 2010-2011-2009
    // this._vehicle.avg_stats.sort((a, b) => {
    //   return a.year - b.year;
    // });
    this.formatView();
  }

  get vehicle(): Vehicle {
    return this._vehicle;
  }

  _vehicle!: Vehicle;
  _vehicleId!: string;

  status$!: Observable<string>;

  @Output() likeBtnCliked = new EventEmitter<boolean>();
  @Output() removeBtnCliked = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter();

  isLoadingStars: boolean = false;
  currentYear = new Date().getFullYear();
  public images!: string[];
  public currentImasgeSelected!: number;

  constructor(
    public translate: TranslateService,
    private vehicleService: VehiclesService,
    private favoritesService: FavoritesService,
  ) { }

  ngOnInit() {

  }

  loadVehicleById() {
    this.isLoadingStars = true;
    this.vehicleService.getVehicle(this._vehicleId).then((vehicle) => {
      this.vehicle = vehicle;
      // this._vehicle.avg_stats.sort((a, b) => {
      //   return a.year - b.year
      // });
      this.isLoadingStars = false;
      // this.formatView();

      this.status$ = this.favoritesService.favorites$.pipe(
        map(favorites => favorites.includes(this.vehicle._id) ? 'liked' : 'none'),
        tap(status => this.vehicle.status = status)
      );
    });
  }

  formatView() {
    this._formatCarTitles();
    this.images = this._generateImagesLinks(this.vehicle);
    this.currentImasgeSelected = 0;
  }

  ngOnChanges() {
    // this.images = this._generateImagesLinks(this.vehicle);
    // this.currentImasgeSelected = 0;
  }

  public favoriteEvent(isVehicleLiked: boolean) {
    this.likeBtnCliked.emit(isVehicleLiked);
  }

  public removeVehicle(): void {
    this.removeBtnCliked.emit();
  }

  public onClickCarThumbnail(pictureIndex: number) {
    this.currentImasgeSelected = pictureIndex;
  }

  private _formatCarTitles() {
    this.vehicle.make.toLowerCase();
    this.vehicle.model.toLowerCase();
  }

  private _generateImagesLinks(vehicle: Vehicle): string[] {
    const apiUrl: string = environment.apiUrl + "/files/";
    const images = [];
    if (vehicle.images != null) {
      for (let i = 0; i < vehicle.images.length; i++) {
        images.push(apiUrl + vehicle.images[i]);
      }
    }
    return [...(vehicle.thumbnail ? [apiUrl + vehicle.thumbnail] : []), ...(images ? images : [])];
  }

  public onClose() {

    this.closeModal.emit();
  }

}
