import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomFile } from '../entities/custom_file';
import { Inspection } from '../entities/inspections/inspection';
import { Inspector } from '../entities/inspector';
import { Purchase } from '../entities/purchase';
import { AuthService } from './auth/auth.service';
import { psi } from '../entities/forms/psi';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  inspectorUrl = environment.apiUrl + '/inspector/inspection';
  clientUrl = environment.apiUrl + '/client/inspections';
  currentInspectionId!: string;

  constructor(private auth: AuthService, private http: HttpClient) {}

  async createInspection(inspection: Inspection): Promise<string> {
    let response = firstValueFrom(
      this.http.post<string>(this.inspectorUrl + '/create', {
        data: inspection,
      })
    ).catch((error) => {
      throw new Error(error);
    });
    let inspectionPassword;
    [this.currentInspectionId, inspectionPassword] = await response;
    localStorage.setItem('currentInspection', this.currentInspectionId);

    return this.currentInspectionId;
  }

  async saveInspection(inspection: Inspection): Promise<string> {
    return firstValueFrom(
      this.http.patch<string>(this.inspectorUrl + `/${inspection._id}`, {
        data: inspection,
      })
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getInspection(inspection_id: string) {
    return firstValueFrom(
      this.http.get<Inspection>(this.inspectorUrl + `/${inspection_id}`)
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getInspectorInfo(inspector_id: string) {
    return firstValueFrom(
      this.http.get<Inspector>(
        this.clientUrl + `/inspectorInfo/${inspector_id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getOrganizedInspection(
    inspection_id: string,
    inspection_password: string
  ) {
    const password_url = inspection_password ? '/' + inspection_password : '';
    return firstValueFrom(
      this.http.get<Inspection>(
        this.clientUrl + `/organized/${inspection_id}${password_url}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getProducedInspections(inspector_id: string) {
    return firstValueFrom(
      this.http.get<any>(
        this.inspectorUrl + `/producedInspections/${inspector_id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async getUserInspection(inspectionPdfId: string) {
    const token = await this.auth.token();
    return firstValueFrom(
      this.http.get<CustomFile>(
        this.clientUrl + `/userInspection/${inspectionPdfId}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async createNotConfirmedInspectionPurchase(purchase: Purchase) {
    return firstValueFrom(
      this.http.post<string>(this.clientUrl + '/createNotConfirmedPurchase', {
        data: purchase,
      })
    );
  }

  async createInspectionPurchase(purchase: Purchase) {
    return firstValueFrom(
      this.http.post<string>(this.clientUrl + '/createPurchase', {
        data: purchase,
      })
    );
  }

  async checkAdminDaysOff(): Promise<boolean> {
    return firstValueFrom(
      this.http.get<boolean>(this.inspectorUrl + '/checkDaysOff')
    );
  }

  async getMarketValue(
    make: string,
    model: string,
    milage: string,
    year: string,
    id: string
  ) {
    return firstValueFrom(
      this.http.get<string>(
        this.clientUrl +
          `/getMarketValue/${make}/${model}/${milage}/${year}/${id}`
      )
    ).catch((error) => {
      throw new Error(error);
    });
  }

  async verifyVin(niv: string): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(this.inspectorUrl + `/vinDecoder/${niv}`)
    );
  }

  async createPsiInspection(psiForm: psi): Promise<any> {
    return firstValueFrom(
      this.http.post<string>(this.inspectorUrl + '/createPsiInspection', {
        data: psiForm,
      })
    );
  }

  async getPsiInspection(objectId: string): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(this.inspectorUrl + `/getPsiInspection/${objectId}`)
    );
  }
}
