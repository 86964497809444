import { Component, NgZone } from '@angular/core';
import { getIdToken, sendEmailVerification } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { Graphics } from 'src/app/entities/graphics';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraphicsService } from 'src/app/services/graphics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {

  email!: string;
  password!: string;

  isLoading: boolean = false;
  isAllLoading: boolean = false;
  alternateGraphics!: Graphics;
  originParam!: string;
  type: string;

  error!: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public fauth: AngularFireAuth,
    public translate: TranslateService,
    private ngZone: NgZone,
    private graphicsService: GraphicsService,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.type = this.route.snapshot.data['type'];

    this.fauth.setPersistence('local')
  }

  async loginGoogle() {
    this.isLoading = true;
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }
    await this.fauth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()).catch((error) => this.errorHandling(error));
  }

  async loginFacebook() {
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }
    this.fauth.signInWithRedirect(new firebase.auth.FacebookAuthProvider()).catch((error) => this.errorHandling(error));
  }

  async loginEmail() {
    if (this.email != null && this.email != "" && this.password != null && this.password != "") {
      this.isLoading = true;
      this.error = '';
      await this.fauth.signInWithEmailAndPassword(this.email, this.password).then((login) => { this.login(); }).catch((error) => this.errorHandling(error));
    } else {
      this.translate.get('errors.missing-infos').subscribe((res: string) => {
        this.error = res;
      });
    }
  }

  async loginB2b() {
    // It is a bad practice to send the password as a query parameter, but it is the simplest way to do it
    // and it is not a security issue for the moment since this is not a user specific password and few people have the link
    if (this.password != null && this.password != "") {
      this.isLoading = true;
      this.error = '';
      let token = await this.authService.verifyBusinessPassword(this.password).catch((error) => this.errorHandling(error.error));

      if (token != null && token != "") {
        this.fauth.signInWithCustomToken(token as string)
          .then(login => {
            this.login();
          })
          .catch(error => {
            this.errorHandling(error);
          })
      } else {
        let err = new Error("login not valid");
        await this.errorHandling(err);
      }
    } else {
      this.translate.get('errors.missing-infos').subscribe((res: string) => {
        this.error = res;
      });
    }
  }

  async signupEmail() {
    this.isLoading = true;
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }

    if (!this.email || !this.password) {
      return;
    }

    await this.fauth.createUserWithEmailAndPassword(this.email, this.password).then(async (user) => {
      var origin = localStorage.getItem("origin");
      let currentUser = await this.fauth.currentUser;
      if (currentUser) sendEmailVerification(currentUser, { url: environment.url + ((origin != null) ? origin : "") });
      this.ngZone.run(() => this.login());
    }, (error) => this.errorHandling(error));
  }

  async login() {
    let currentUser = await this.fauth.currentUser;
    let idToken: string | null = null;
    if (currentUser) idToken = await getIdToken(currentUser);
    if (idToken) this.authService.login(idToken, this.originParam);
  }

  async errorHandling(error: any) {
    if (error['code'] == null) {
      this.isLoading = false;
      return;
    }

    this.translate.get(error['code'].toString().replace('auth/', 'errors.')).subscribe((res: string) => {
      if (res) {
        this.error = res;
      } else {
        this.translate.get('errors.error').subscribe((res: string) => {
          this.error = res;
        });
      }
    });

    this.isLoading = false;
  }

}
