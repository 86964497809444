import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../material.module';
import { VehicleDetailsComponent } from '../modules/client/components/vehicle-details/vehicle-details.component';
import { LoadingModule } from '../modules/loading/loading.module';
import { PipesModule } from '../pipes/pipes.module';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { ErrorComponent } from './components/error/error.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormChoiceComponent } from './components/form/form-choice/form-choice.component';
import { FormComponent } from './components/form/form.component';
import { KiwiRatingComponent } from './components/kiwi-rating/kiwi-rating.component';
import { HelpButtonComponent } from './components/menu/help-button/help-button.component';
import { AccountHeaderComponent } from './components/menu/menu-mobile/account-header/account-header.component';
import { MenuMobileComponent } from './components/menu/menu-mobile/menu-mobile.component';
import { MenuComponent } from './components/menu/menu.component';
import { TranslateButtonComponent } from './components/menu/translate-button/translate-button.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { FormFinishedModalComponent } from './modals/form-finished-modal/form-finished-modal.component';
import { HelpModalComponent } from './modals/help-modal/help-modal.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { ModalNewInspectionTypeComponent } from './modals/modal-new-inspection-type/modal-new-inspection-type.component';
import { ModalVehicleDetailsComponent } from './modals/modal-vehicle-details/modal-vehicle-details.component';
import { ProfileUpdateComponent } from './modals/profile-update/profile-update.component';

const modals = [
  ModalVehicleDetailsComponent,
  ModalMessageComponent,
  ModalNewInspectionTypeComponent,
  FormFinishedModalComponent,
  HelpModalComponent,
];

const components = [
  ErrorNotFoundComponent,
  VehicleDetailsComponent,
  KiwiRatingComponent,
  TranslateButtonComponent,
  ProfileUpdateComponent,
  MenuComponent,
  MenuMobileComponent,
  AccountHeaderComponent,
  HelpButtonComponent,
  UserImageComponent,
  ErrorComponent,
  FormComponent,
  FormChoiceComponent,
  FileUploadComponent,

  // Modals
  ...modals
];

@NgModule({
  declarations: components,
  imports: [
    TranslateModule,
    MaterialModule,
    PipesModule,
    CommonModule,
    RouterModule,
    FormsModule,
    LoadingModule,
    NgxMaskModule,
  ],
  entryComponents: modals,
  exports: [
    ...components,
  ]
})
export class SharedModule { }
