<div
  id
  class="container-half"
  #top
  *ngIf="inspection$ | async as loadedInspection; else loadingTemplate"
>
  <div class="container-half-box1">
    <h2>
      {{ loadedInspection.data?.identification?.make }}
      {{ loadedInspection.data?.identification?.model }}
      {{ loadedInspection.data?.identification?.year }}
    </h2>
    <hr class="solid" />
    <ng-container *ngIf="firstPhotoSrc$ | async as loadedSrc">
      <img id="firstPhoto" src="{{ loadedSrc }}" alt="" />
    </ng-container>
  </div>

  <div class="container-half-box2">
    <h2>
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.opinion.title"
          | safeTranslate
      }}
    </h2>
    <hr class="solid" />

    <ng-container
      [ngSwitch]="
        oldInspection(loadedInspection.date)
          ? 1
          : loadedInspection.data?.finalRecommendation
          ? 2
          : -1
      "
    >
      <ng-container *ngSwitchCase="1">
        <span class="opinion">{{
          "client.inspections.reports.inspection_details.tab_synthesis.recommended_purchase"
            | safeTranslate
        }}</span>
        <span
          [ngClass]="{
            greenText:
              loadedInspection.data?.recommendedPurchase === 'Oui' ||
              loadedInspection.data?.recommendedPurchase ===
                'Oui avec réparations recommandées' ||
              loadedInspection.data?.recommendedPurchase === 'Yes' ||
              loadedInspection.data?.recommendedPurchase ===
                'Yes, with recommended repairs',
            redText:
              loadedInspection.data?.recommendedPurchase === 'Non' ||
              loadedInspection.data?.recommendedPurchase === 'No'
          }"
          class="opinion"
          ><b>{{
            "inspection.comments." + loadedInspection.data?.recommendedPurchase
              | translate
          }}</b></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <span class="opinion">{{
          "client.inspections.reports.inspection_details.tab_synthesis.recommended_purchase"
            | safeTranslate
        }}</span>
        <span
          [ngClass]="{
            greenText:
              loadedInspection.data?.finalRecommendation === 'Oui' ||
              loadedInspection.data?.finalRecommendation ===
                'Oui avec réparations recommandées' ||
              loadedInspection.data?.finalRecommendation === 'Yes' ||
              loadedInspection.data?.finalRecommendation ===
                'Yes, with recommended repairs',
            redText:
              loadedInspection.data?.finalRecommendation === 'Non' ||
              loadedInspection.data?.finalRecommendation === 'No'
          }"
          class="opinion"
          ><b>{{
            "inspection.comments." + loadedInspection.data?.finalRecommendation
              | translate
          }}</b></span
        >
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span class="opinion">{{
          "client.inspections.reports.inspection_details.tab_synthesis.waitingForRecommendation"
            | safeTranslate
        }}</span>
      </ng-container>
    </ng-container>

    <p
      *ngIf="
        loadedInspection.data?.finalOffer &&
        loadedInspection.data?.recommendedPurchase == undefined
      "
    >
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.finalOffer"
          | safeTranslate
      }}
      <span>{{ loadedInspection.data?.finalOffer }}</span>
    </p>

    <ng-container *ngIf="loadedInspection.data?.isAKiwiz">
      <br />
      <br />
      <span class="opinion">
        {{ loadedInspection.data?.isAKiwiz }}
      </span>
    </ng-container>

    <ng-container *ngIf="loadedInspection.data?.overallAesthetic">
      <br /><br />
      <span class="opinion">{{
        "client.inspections.reports.inspection_details.tab_synthesis.opinion.intro"
          | translate
      }}</span>
      <ul>
        <li class="opinion" *ngIf="loadedInspection.data?.overallAesthetic">
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.opinion.aesthetic." +
              loadedInspection.data?.overallAesthetic | translate
          }}
        </li>
        <li class="opinion" *ngIf="loadedInspection.data?.overallMechanical">
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.opinion.mechanical." +
              loadedInspection.data?.overallMechanical | translate
          }}
        </li>
        <li class="opinion" *ngIf="loadedInspection.data?.overallFunctional">
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.opinion.functional." +
              loadedInspection.data?.overallFunctional | translate
          }}
        </li>
      </ul>
      <span class="opinion">{{
        "client.inspections.reports.inspection_details.tab_synthesis.opinion.conclusion"
          | translate
      }}</span>
    </ng-container>
  </div>
</div>

<div class="container-full" #middle>
  <div class="container-full-box1">
    <h2>
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.cost_of_repairing"
          | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <table id="default_table">
      <tr>
        <th>
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.required_to_repair"
              | safeTranslate
          }}
        </th>
        <th id="desktop_recommandations">
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.recommendation.title"
              | safeTranslate
          }}
        </th>
        <th id="cost_column">
          {{
            "client.inspections.reports.inspection_details.tab_synthesis.cost"
              | safeTranslate
          }}
          ($)
        </th>
      </tr>
      <tr *ngFor="let repair of mandatoryRepairs$ | async">
        <td>
          <span id="fieldTitle"
            >{{ "inspection.fields." + repair.part | safeTranslate }} :</span
          >
          {{ repair.comment | safeTranslate : "inspection.comments." }}
          <span id="mobile_recommandations"
            ><br />• {{ repair.recommandations }}</span
          >
        </td>
        <td id="desktop_recommandations">{{ repair.recommandations }}</td>
        <td>{{ repair.cost }}</td>
      </tr>
    </table>
    <p
      *ngIf="(mandatoryRepairs$ | async)?.length == 0"
      style="margin-bottom: 0px; margin-left: 15px"
    >
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.no_repair_required"
          | safeTranslate
      }}
    </p>
    <h3 style="text-align: end; margin-bottom: 40px">
      Total: {{ mandatoryCost$ | async }} $
    </h3>
  </div>
</div>
<div
  [ngClass]="
    purchase$.value?.data?.marketValue ? 'container-half' : 'container-full'
  "
  *ngIf="inspector$ | async as loadedInspector"
  #bottom
>
  <div
    [ngClass]="
      purchase$.value?.data?.marketValue
        ? 'container-half-box1'
        : 'container-full-box1'
    "
  >
    <h2>
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.inspector_kiwiz"
          | safeTranslate
      }}
      {{ loadedInspector.firstName + " " + loadedInspector.lastName }}
    </h2>
    <hr class="solid" />
    <div class="inspector-box">
      <div class="box1">
        <div class="picture">
          <img
            *ngIf="
              loadedInspector.photo &&
                loadedInspector.photo != '' &&
                loadedInspector.photo != null;
              else randomPhoto
            "
            src="{{ loadedInspector.photo }}"
          />
          <ng-template #randomPhoto>
            <img src="assets/inspectors/imgInspecteur.png" />
          </ng-template>
        </div>
      </div>

      <div class="box2">
        <p
          *ngIf="
            this.language == 'en' && loadedInspector.description_en;
            else frenchDescription
          "
          style="color: black"
        >
          {{ loadedInspector.description_en }}
        </p>
        <ng-template #frenchDescription>
          <p style="color: black">{{ loadedInspector.description }}</p>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="container-half-box2" *ngIf="purchase$?.value?.data?.marketValue">
    <h2>
      {{
        "client.inspections.reports.inspection_details.tab_synthesis.upsale.title"
          | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <br />
    {{
      ("client.inspections.reports.inspection_details.tab_synthesis.upsale.overall"
        | safeTranslate)[0]
    }}
    {{ (marketValue$ | async)?.data?.country_count }}
    {{ (marketValue$ | async)?.data?.make | lowercase }}
    {{ (marketValue$ | async)?.data?.model | lowercase }}
    {{ (marketValue$ | async)?.data?.year }}
    {{
      ("client.inspections.reports.inspection_details.tab_synthesis.upsale.overall"
        | safeTranslate)[1]
    }}
    <br />

    <ul>
      <li>
        {{
          "client.inspections.reports.inspection_details.tab_synthesis.upsale.avgIndividuals"
            | safeTranslate
        }}
        {{ (marketValue$ | async)?.data?.market_price_individual }}$
      </li>

      <li>
        {{
          "client.inspections.reports.inspection_details.tab_synthesis.upsale.avgMarkte"
            | safeTranslate
        }}
        {{ (marketValue$ | async)?.data?.market_price_pro }}$
      </li>

      <li>
        {{
          "client.inspections.reports.inspection_details.tab_synthesis.upsale.mileage"
            | safeTranslate
        }}
        {{ (marketValue$ | async)?.data?.avg_dist_perYear }} KM
      </li>

      <li>
        {{
          ("client.inspections.reports.inspection_details.tab_synthesis.upsale.avgSellTime"
            | safeTranslate)[0]
        }}
        {{ (marketValue$ | async)?.data?.time_to_sell }}
        {{
          ("client.inspections.reports.inspection_details.tab_synthesis.upsale.avgSellTime"
            | safeTranslate)[1]
        }}
      </li>
    </ul>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading-secondary></app-loading-secondary>
</ng-template>
