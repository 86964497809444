<ng-container *ngIf="!multipleFiles; else multiple">
    <input *ngIf="file == null || unlimitedFiles" type="file" #fileInput (change)="compressAndUploadFile($event)">
</ng-container>
<ng-template #multiple>
    <input *ngIf="file == null || unlimitedFiles" type="file" #fileInput (change)="compressAndUploadFile($event)"
        multiple="multiple">
</ng-template>


<ng-container *ngIf="uploading">
    <app-loading-secondary></app-loading-secondary>
</ng-container>

<ng-container *ngIf="showFile && file !=null">
    <div class="confirmed-upload-wrapper" *ngIf="file.url">
        <span class="confirmed-upload">{{ 'form.confirmedUpload' | translate }}</span>
        <!-- <img [src]="file.url" *ngIf="file.url"> -->
        <mat-icon>description</mat-icon>
    </div>
</ng-container>
<button *ngIf="!autoUpload && file !=null" (click)="upload(file)">
    {{ 'general.save' | translate }}
</button>