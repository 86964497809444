import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetConfirmationComponent } from '../authentication/components/password-reset-confirmation/password-reset-confirmation.component';
@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent implements OnInit {

  mode!: string;
  oobCode!: string;
  password!: string;
  confirmation!: string;

  constructor(
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private dialog: MatDialog
  ) {
    this.route.queryParams.subscribe(async (params) => {
      this.oobCode = params["oobCode"];
      console.log("THIS:", this);
      console.log("MODE", this.mode);
      console.log("OOB", this.oobCode);
      await this.auth.checkActionCode(this.oobCode).then((value) => {
        this.mode = value.operation;
        console.log(value);
        if (value.operation == 'VERIFY_EMAIL') {
          this.auth.applyActionCode(this.oobCode);
        }
      }, (error) => {
        console.log(error);
      })

    });
  }
  ngOnInit() {
  }

  async resetPassword() {
    await this.auth.confirmPasswordReset(this.oobCode, this.password).then(() => {
      this.showPasswordResetMessage();//this.router.navigate(['client', 'informations']); //
    }, (error) => {

    })
  }

  
  /**
   * Opens a windows that shows the user a confirmation message that their password has been succesfully reset.
   * Has been added as a temporary solution while this.router.navigate(['client', 'informations']) does not seem to work. 
   */
    showPasswordResetMessage() {
      this.dialog.open(PasswordResetConfirmationComponent, {
        panelClass: ['modal', 'small'],
        maxHeight: "80vh",
        data: {},
        autoFocus: false,
      });
    }
}


