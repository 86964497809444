import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationComponent} from "../../authentication.component";
import {MatDialog} from "@angular/material/dialog";
import {PasswordResetComponent} from "../../modals/password-reset/password-reset.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-business-login',
  templateUrl: './business-login.component.html',
  styleUrls: ['./business-login.component.scss']
})
export class BusinessLoginComponent implements OnInit {

  @Input() authenticationComponent!: AuthenticationComponent;
  isFrench = false;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService) {
  }

  ngOnInit() {
  }

  forgotPassword() {
    this.dialog.open(PasswordResetComponent, {
      panelClass: ['modal', 'small'],
      maxHeight: "80vh",
      data: {},
      autoFocus: false,
    });
  }

  switchLanguage(language: string): void {
    this.translate.use(language);
    this.isFrench = language === "fr";
  }
}
