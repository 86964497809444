import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Answer } from 'src/app/entities/forms/answer';
import { Form } from 'src/app/entities/forms/form';
import { Question } from 'src/app/entities/forms/question';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() formId!: string;
  @Input() showFormTitle: boolean = true;
  @Output() onSubmit = new EventEmitter<Answer[]>();
  @Output() onSave = new EventEmitter<Answer[]>();

  form = new BehaviorSubject<Form | null>(null);
  // Could probably be replaced by a dictionary
  @Input() answers: Answer[] = [];

  get inspectionId(): string | null {
    return localStorage.getItem('currentInspection');
  }

  @Input() currentPageIndex: number = 0;

  constructor(private formService: FormService) { }

  ngOnInit() {
    this.formService.getForm(this.formId).then((form) => {
      if (form != null) {
        for (let page of form.pages) {
          for (let question of page.questions) {
            if (!this.answers.find(answer => answer.key == question.return_key)) {
              this.answers.push(new Answer(question.return_key, question.initial_value ?? null));
            }
          }
        }
      }
      this.currentPageIndex = this.mod(this.currentPageIndex, form.pages.length);
      this.form.next(form);
    });
  }

  /**
   * Returns the answer for the given key or null.
   * @param returnKey The key of the answer
   * @param value The value of the answer
   * @returns The value of the answer
   */
  getAnswer(returnKey: string, value: string | null = null): Answer | undefined {
    return this.answers.find(answer => answer.key == returnKey);
  }

  /**
   * Returns the answer for the given key or creates a new one if it doesn't exist.
   * @param returnKey The key of the answer
   * @param value The value of the answer
   * @returns The value of the answer
   */
  getOrCreateAnswer(returnKey: string, value: string | null = null): Answer {
    let answer = this.answers.find(answer => answer.key == returnKey);
    if (!answer) {
      answer = new Answer(returnKey, value);
      this.answers.push(answer);
    }
    return answer;
  }

  save() {
    this.onSave.emit(this.answers);
  }

  currentPageCompleted(): boolean {
    for (let question of this.form.value?.pages[this.currentPageIndex].questions ?? []) {
      var currentAnswer = this.getAnswer(question.return_key);

      if (question.type == 'order' && currentAnswer && currentAnswer.value == null) {
        this.answers[this.answers.indexOf(currentAnswer)].value = question.choices;
      }

      if ((question.optional == null || question.optional == false) && currentAnswer?.value == null || (question.type == 'text' && question.answer_length != null && currentAnswer?.value.toString().length < question.answer_length)) {

        if (question.conditions == null || question.conditions.length == 0) {
          return false;
        }

        if (question.conditions != null && question.conditions.length > 0 && this.questionCanDisplay(question)) {
          return false;
        }
      }

      // Covers questions describing the other condition.
      if (typeof currentAnswer?.value === 'string' && (currentAnswer?.value.includes('Autre') || currentAnswer?.value.includes('Other'))) {
        let answer = this.getAnswer(currentAnswer.key +'Other');
        if (answer?.value == null || answer.value == '') {
          return false;
        }
      }

      // Covers recommendation and cost questions when the inspector wants to fill them.
      if (typeof currentAnswer?.value === 'string' && (currentAnswer?.value.includes('recommandations/prix') || currentAnswer?.value.includes('price/recommendation'))) {
        for (let key of [currentAnswer.key.replace('CostQuestion','Recommandation'), currentAnswer.key.replace('CostQuestion','Cost')]) {
          let answer = this.getAnswer(key);
          if (answer?.value == null || answer.value == '') {
            return false;
          }
        }
      }
    }

    return true;
  }

  drop(returnKey: string, choices: any[], event: CdkDragDrop<string[]>) {
    const answer = this.getAnswer(returnKey);
    if (!answer) return;

    const indexOfAnswer = this.answers.indexOf(answer);
    if (this.answers[indexOfAnswer].value == null) {
      this.answers[indexOfAnswer].value = choices;
    }
    moveItemInArray(this.answers[indexOfAnswer].value, event.previousIndex, event.currentIndex);
    this.save();
  }

  isSingleChoiceSelected(returnKey: string, choice: any) {
    const answer = this.getAnswer(returnKey);
    if (!answer) return false;

    const indexOfAnswer = this.answers.indexOf(answer);
    if (choice.value) return this.answers[indexOfAnswer]?.value?.value === choice.value;
    return this.answers[indexOfAnswer]?.value === choice;
  }

  selectSingleChoice(returnKey: string, choice: any) {
    const answer = this.getAnswer(returnKey);
    if (!answer) return;

    const indexOfAnswer = this.answers.indexOf(answer);
    this.answers[indexOfAnswer].value = choice;
    this.save();
  }

  isMultipleChoiceSelected(returnKey: string, choice: any) {
    const answer = this.getAnswer(returnKey);
    if (!answer) return false;

    const indexOfAnswer = this.answers.indexOf(answer);
    return (this.answers[indexOfAnswer].value != null) ? this.answers[indexOfAnswer].value.includes(choice) : false;
  }

  selectMultipleChoice(returnKey: string, choice: any, type?: any) {
    const answer = this.getAnswer(returnKey);
    if (!answer) return;

    const indexOfAnswer = this.answers.indexOf(answer);

    if (type == 'choicesAndNoPreferences') {
      this.noPreferencesHandler(choice, indexOfAnswer); //handling the case of multiple choices with the 'No preferences' answer selected
    }

    if (this.answers[indexOfAnswer].value == null) {
      this.answers[indexOfAnswer].value = [];
    }

    if (this.answers[indexOfAnswer].value.includes(choice)) { // if choice is already selected, it will be removed
      this.answers[indexOfAnswer].value.splice(this.answers[indexOfAnswer].value.indexOf(choice), 1);
    } else { // the choice is not already selected then it will be added
      this.answers[indexOfAnswer].value.push(choice);
    }

    this.save();
  }

  noPreferencesHandler(choice: any, indexOfAnswer: number) {

    let formattedChoice = typeof (choice) == 'object' ? choice.value : choice;

    //if "No preferences" is selected, all the others selected choices are removed
    if (formattedChoice == "Pas de préférence" || formattedChoice == "No preferences") {
      this.answers[indexOfAnswer].value = [];

    } else if (this.answers[indexOfAnswer].value) {  //"No preferences" is searched and removed, if another choice is selected 

      for (let value of this.answers[indexOfAnswer].value) {

        value = typeof (choice) == 'object' ? value.value : value;

        if (value == "Pas de préférence" || value == "No preferences") {
          this.answers[indexOfAnswer].value.splice(this.answers[indexOfAnswer].value.indexOf(choice), 1);
        }
      }

    }

  }

  questionCanDisplay(question: Question) {
    if (!question || !question.conditions) return true;

    return this.calculateConditions(question.conditions);
  }

  calculateConditions(conditions: any): any {
    if (conditions[0]) {
      if (conditions[0] === 'and') {
        for (var i = 1; i < conditions.length; i++) {
          if (!this.calculateConditions(conditions[i])) {
            return false;
          }
        }
        return true;
      } else if (conditions[0] === 'or') {
        for (var i = 1; i < conditions.length; i++) {
          if (this.calculateConditions(conditions[i])) {
            return true;
          }
        }
        return false;
      } else if (conditions[0] === 'not') {
        return !this.calculateConditions(conditions[1]);
      }

      // Single condition, modify to use next part of function
      conditions = conditions[0];
    }

    var answer = this.getAnswer(conditions.return_key);
    if (!answer) return false;

    answer = answer.value
    if (Array.isArray(answer)) {
      const x = answer.find(x => x == conditions.value);
      return x != null;
    }

    // Next line is for answers that are not simple strings (like the ones with images)
    if (answer?.value) return answer.value === conditions.value;
    return answer === conditions.value;
  }

  back() {
    if (this.currentPageIndex == 0) {
      return;
    }

    this.currentPageIndex--;
  }

  next() {
    if (!this.currentPageCompleted()) {
      return;
    }

    if (this.form.value && this.currentPageIndex === this.form.value.pages.length - 1) {

      for (let i = 0; i < this.answers.length; i++) {
        if (this.answers[i].value != null) {
          if (Array.isArray(this.answers[i].value)) {
            var temp = [];

            for (let j = 0; j < this.answers[i].value.length; j++) {

              if (this.answers[i].value[j].value != null) {
                temp.push(this.answers[i].value[j].value);
              } else {
                temp.push(this.answers[i].value[j]);
              }
            }

            this.answers[i].value = temp;

          } else if (this.answers[i].value['value'] != null) {
            this.answers[i].value = this.answers[i].value['value'];
          }
        }
      }

      this.onSubmit.emit(this.answers);
      return;
    }
    this.save();
    this.currentPageIndex++;
    window.scrollTo(0, 0);
  }

  mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }
}
