<div class="btn-group">
  <button
    (click)="translateInFrench($event)"
    [class.selected]="currentLang$.value === 'fr'"
    [class.white-color]="color === 'white'"
  >
    FR
  </button>
  <button
    (click)="translateInEnglish($event)"
    [class.selected]="currentLang$.value === 'en'"
    [class.white-color]="color === 'white'"
  >
    EN
  </button>
</div>
