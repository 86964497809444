<ng-container *ngIf="!isAllLoading; else loading">

    <div class="auth-bg"></div>

    <div class="flex">

        <div class="auth-form">
            <div class="form">

                <div class="content">
                    <div class="error-inline" *ngIf="error && !!error.length">
                        <svg>
                            <use xlink:href="/assets/sprite.svg#kiwiz-icon-alert"></use>
                        </svg>
                        <p><b>{{ error }}</b></p>
                    </div>
                    <ng-container *ngIf="type == 'login-b2b'">
                      <app-business-login [authenticationComponent]="this">
                      </app-business-login>
                    </ng-container>

                    <ng-container *ngIf="type == 'signup'">
                        <app-signup [authenticationComponent]="this">
                        </app-signup>
                    </ng-container>

                    <ng-container *ngIf="type == 'confirmation'">
                        <app-confirmation>
                        </app-confirmation>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="graphics">
            <app-authentication-graphics [alternateGraphics]="alternateGraphics"></app-authentication-graphics>
        </div>

    </div>

</ng-container>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
