import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from 'src/app/services/settings.service';
import { HelpModalComponent } from 'src/app/shared/modals/help-modal/help-modal.component';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {

  url!: string;
  

  constructor(private settingsService: SettingsService, private dialog: MatDialog) {
    this.settingsService.getHelpUrl().then((link: string | undefined) => {
      if (link) this.url = link;
    })
  }

  ngOnInit() {
  }

  openHelp() {
    if (this.url != null) {
      if (window.innerWidth <= 1024) {
        location.href = this.url;
        return;
      }

      this.dialog.open(HelpModalComponent, {
        panelClass: ['modal'],
        maxWidth: "80vw",
        width: "80vw",
        data: {
          url: this.url
        },
        autoFocus: false,
      });
    }
  }

}
