export class Page<T> {
    data: T;
    itemCount : number;
    pages: any[];

    constructor(itemCount : number, pages: any[], data : T){
        this.itemCount = itemCount;
        this.pages = pages;
        this.data = data;
    }
}
