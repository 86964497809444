<ng-container *ngIf="form | async as form; else loadingTemplate">
    <div class="form">
        <h1 *ngIf="showFormTitle">{{ form.title }}</h1>
        <div class="pages">
            <div class="page">
                <div class="questions">
                    <div class="question" *ngFor="let question of form.pages[currentPageIndex].questions">
                        <ng-container *ngIf="questionCanDisplay(question)">
                            <div class="title">
                                <h4>{{ question.title }}</h4>
                            </div>
                            <div class="description" *ngIf="question.description"
                                [innerHtml]="question.description | safeHtml"></div>

                            <div class="answer" [ngSwitch]="question.type">
                                <ng-container *ngSwitchCase='"choice"'>
                                    <div
                                        class="choices {{ (question.direction != null) ? question.direction : 'column' }}">
                                        <app-form-choice *ngFor="let choice of question.choices" [choice]="choice"
                                            (click)="selectSingleChoice(question.return_key, choice)"
                                            [selected]="isSingleChoiceSelected(question.return_key, choice)">
                                        </app-form-choice>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase='"choices"'>
                                    <div
                                        class="choices {{ (question.direction != null) ? question.direction : 'column' }}">
                                        <app-form-choice *ngFor="let choice of question.choices" [choice]="choice"
                                            (click)="selectMultipleChoice(question.return_key, choice)"
                                            [selected]="isMultipleChoiceSelected(question.return_key, choice)">
                                        </app-form-choice>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase='"choicesAndNoPreferences"'>
                                    <div
                                        class="choices {{ (question.direction != null) ? question.direction : 'column' }}">
                                        <app-form-choice *ngFor="let choice of question.choices" [choice]="choice"
                                            (click)="selectMultipleChoice(question.return_key, choice, 'choicesAndNoPreferences')"
                                            [selected]="isMultipleChoiceSelected(question.return_key, choice)">
                                        </app-form-choice>
                                    </div>
                                </ng-container>

                                <!-- <ng-container *ngSwitchCase='"order"'>
                                    <div cdkDropList class="choices column order order-list"
                                        [cdkDropListData]="question.choices"
                                        (cdkDropListDropped)="drop(question.return_key, question.choices, $event)">
                                        <div class="order" *ngFor="let choice of question.choices; let i = index;"
                                            [cdkDragData]="choice.value" cdkDrag>
                                            <span>{{ (i + 1) }}</span>
                                            <mat-icon>drag_indicator</mat-icon>
                                            <app-form-choice [choice]="choice" [noHover]="true">
                                            </app-form-choice>
                                        </div>
                                    </div>
                                </ng-container> -->
                                <ng-container *ngSwitchCase='"images"'>
                                    <app-file-upload *ngIf="answers && getAnswer(question.return_key) as answer" [unlimitedFiles]="true" [multipleFiles]="true"
                                        [showFile]="true"
                                        [file]="answer.value"
                                        (onUploaded)="answer.value = $event;
                                                      answer.value.description = question.title;
                                                      save()"></app-file-upload>
                                </ng-container>
                                <ng-container *ngSwitchCase='"text"'>
                                    <input type="text" *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name" [mask]="question.mask"
                                        [maxlength]="question.answer_length">
                                </ng-container>
                                <ng-container *ngSwitchCase='"textbox"'>
                                    <textarea *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name" [mask]="question.mask"
                                        [maxlength]="question.answer_length"
                                    ></textarea>
                                </ng-container>
                                <ng-container *ngSwitchCase='"date"'>
                                    <input type="date" *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name">
                                </ng-container>

                                <!-- COMBO BOX : specified the differentes values in an array in the form with  "comboboxValues" : [ ...... ]-->
                                <ng-container *ngSwitchCase='"combobox"'>
                                    <select *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name">
                                        <option *ngFor="let number of question.comboboxValues">{{number}}
                                        </option>
                                    </select>
                                </ng-container>


                                <ng-container *ngSwitchCase='"number"'>
                                    <input type="number" *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name" [min]="question.min" [max]="question.max"
                                        [step]="question.step" [mask]="question.mask">
                                </ng-container>

                                <ng-container *ngSwitchCase='"termsAgreement"'>
                                    <mat-checkbox class="checkbox-conditions" *ngIf="answers && getAnswer(question.return_key) as answer" (focusout)="save()"
                                        [(ngModel)]="answer.value"
                                        [name]="question.name">

                                        {{ 'dashboard.conditions' | translateWithLink: 0 }}
                                        <a href="https://carinspect.com/conditions-of-use/" target="_blank">{{
                                            'dashboard.conditions' |
                                            translateWithLink: 1 }}</a>
                                        {{ 'dashboard.conditions' | translateWithLink: 2 }}
                                        <a href="https://carinspect.com/privacy-policy/"
                                            target="_blank">{{ 'dashboard.conditions' |
                                            translateWithLink: 3 }}</a>
                                        {{ 'dashboard.conditions' | translateWithLink: 4 }}
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- The first getAnswer is used if the answer has a picture and not a direct string value. -->
                        <ng-container *ngIf="question.type !== 'images' && (getAnswer(question.return_key)?.value?.value ?? getAnswer(question.return_key)?.value) as questionValue">
                            <ng-container *ngIf="(questionValue.includes('Autre') || questionValue.includes('Other')) &&
                                                    question.showOtherExplanation !== false &&
                                                    getOrCreateAnswer(question.return_key+'Other') as answer">
                                <label for="otherSpecification">{{ 'client.form.otherSpecification' | safeTranslate}}
                                </label>
                                <input class="otherSpecification" type="text" (focusout)="save()"
                                    name="otherSpecification" [name]="question.name"
                                    [(ngModel)]="answer.value"
                                    [mask]="question.mask">
                            </ng-container>

                            <ng-container
                                *ngIf="(questionValue.includes('recommandations/prix') || questionValue.includes('price/recommendation')) &&
                                    (getAnswer(question.return_key.replace('CostQuestion','Condition')) == undefined ||
                                    getAnswer(question.return_key.replace('CostQuestion','Condition'))?.value != 'Excellent')">
                                <!-- Pensez et checker les questions finissant pas par Condition -->

                                <label for="Recommandation">
                                    {{ 'client.form.recommendation' | safeTranslate}}
                                </label>
                                <input class="otherSpecification" type="text" (focusout)="save()" name="Recommandation"
                                    *ngIf="answers && getOrCreateAnswer(question.return_key.replace('CostQuestion','') +'Recommandation') as answer"
                                    [name]="question.name"
                                    [(ngModel)]="answer.value"
                                    [mask]="question.mask">

                                <label for="Cost">
                                    {{ 'client.form.estimated_cost' | safeTranslate}}
                                </label>
                                <input class="otherSpecification" type="number" (focusout)="save()" name="Cost"
                                    *ngIf="answers && getOrCreateAnswer(question.return_key.replace('CostQuestion','') +'Cost') as answer"
                                    [name]="question.name"
                                    [(ngModel)]="answer.value"
                                    [mask]="question.mask">
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="done-div">
                        <button class="-secondary back" *ngIf="currentPageIndex != 0" [disabled]="currentPageIndex == 0"
                            (click)="back()">{{
                            "general.previous" | translate }}
                        </button>
                        <button class="-secondary next" [disabled]="!currentPageCompleted()" (click)="next()">{{
                            "general.next" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemplate>
    <app-loading-secondary></app-loading-secondary>
</ng-template>