import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUpdateComponent } from 'src/app/shared/modals/profile-update/profile-update.component';
import { NewCodeSentComponent } from '../new-code-sent/new-code-sent.component';
import { sendEmailVerification } from '@angular/fire/auth';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  message!: string;

  constructor(public auth: AngularFireAuth, private authService: AuthService, private dialog: MatDialog) {
  }

  async ngOnInit() {
    let user = await this.auth.currentUser;
    if (!user?.displayName || user?.displayName == '') {
      this.dialog.open(ProfileUpdateComponent, {
        panelClass: ['modal', 'small'],
        maxWidth: "600px",
        data: {},
        autoFocus: false,
      });
    }
  }

  async sendVerificationCode() {
    let origin = localStorage.getItem("origin");
    let user = await this.auth.currentUser;
    if (user) sendEmailVerification(user, { url: environment.url + (origin ?? '')});
    
    this.dialog.open(NewCodeSentComponent, { //window saying the new code has been sent
      panelClass: ['modal', 'small'],
      maxWidth: "600px",
      data: {},
      autoFocus: false,
    });
  }

  disconnect() {
    this.authService.disconnect();
  }

}
