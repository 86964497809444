import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Form } from '../entities/forms/form';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  clientFormsUrl = environment.apiUrl + '/client/forms/';

  constructor(private http: HttpClient) { }

  async getForm(formId: string): Promise<Form> {
    // const token = await this.auth.token();
    return firstValueFrom(this.http.get<Form>(this.clientFormsUrl + encodeURIComponent(formId))).catch(error => {
      throw error;
    });
  }
}
