<header class="header">
    <img class="logo" src="/assets/logo_yellow.svg" routerLink="/client/home">
    <div class="header-toggler" (click)="isOpen = !isOpen">
        <span></span>
        <span></span>
        <span></span>
    </div>
</header>

<div class="bg-cover" *ngIf="isOpen" (click)="isOpen = !isOpen"></div>

<div class="mobile-nav" [ngClass]="{ 'is-open' : isOpen }">
    <app-account-header [showLogo]="false" [disconnect]="false">
    </app-account-header>

    <div (click)="isOpen = false">
        <ng-content></ng-content>
    </div>
    
    <app-help-button></app-help-button>

    <app-translate-button></app-translate-button>
    <div class="mobile-nav__item">
        <button class="disconnect" (click)="disconnectUser()">
            {{ 'general.disconnect' | translate }}
        </button>
    </div>
</div>