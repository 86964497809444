<!-- <mat-toolbar class="modal-toolbar">
  <button mat-icon-button [mat-dialog-close]="isVehicleLiked">
   s
  </button>
</mat-toolbar> -->

<app-vehicle-details
  [vehicleId]="data.vehicle"
  (likeBtnCliked)="handleVehiculeEvaluation($event)"
  (closeModal)="_closeModal()">
</app-vehicle-details>