import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Graphics } from '../entities/graphics';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GraphicsService {
  graphicsUrl = environment.apiUrl + '/graphics/';

  constructor(private http: HttpClient) { }

  async getSequenceGraphics(sequence_uuid: string): Promise<Graphics | void> {
    const url = this.graphicsUrl + sequence_uuid;
    return firstValueFrom(this.http.get<Graphics>(url)).catch(error => {
      console.log(error);
    });
  }
}
