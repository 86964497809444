<section class="vehicle-details" *ngIf="_vehicle && vehicle">
  <div class="headerContainer">
    <button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>

    <!-- Section - Header -->
    <header class="section vehicle-details__header">
      <span class="header-element constructor">
        {{ vehicle.make }}
      </span>
      <span class="header-element model">
        {{ vehicle.model }}
      </span>
      <span class="header-element date">
        ({{ vehicle.years_min }} - {{ (vehicle.years_max) ? vehicle.years_max : currentYear }})
      </span>
    </header>
  </div>

  <!-- Section - Callout -->
  <section class="section callout callout--primary">
    <h2 class="callout__section callout__section--title">{{ 'client.sequence.rating.question' | translate }} </h2>
    <div class="callout__section callout__section--buttons">
      <button
        *ngIf="(status$ | async) === 'disliked' || (status$ | async) === 'none'"
        class="btn"
        mat-raised-button 
        [class.btn--active]="(status$ | async) === 'disliked'"
        (click)="favoriteEvent(true)">
          <div class="btn__content">
            <mat-icon aria-hidden="false" aria-label="Like button">favorite</mat-icon>
            <span>{{ 'client.ads.add_favorite' | translate }}</span>
          </div>
      </button>
      <button
        *ngIf="(status$ | async) === 'liked'"
        mat-raised-button
        [class.btn--active]="(status$ | async) === 'disliked'"
        (click)="favoriteEvent(false)">
          <div class="btn__content">
            <mat-icon aria-hidden="false" aria-label="Unlike button">thumb_down</mat-icon>
            <span>{{ 'client.ads.remove_favorite' | translate }}</span>
          </div>
      </button>
      <!-- <button
        *ngIf="canRemove"
        (click)="removeVehicle(false)">
          <div class="btn__content">
            <mat-icon aria-hidden="false" aria-label="Unlike button">not_interested</mat-icon>
            <span>Pas intéressé</span>
          </div>
      </button> -->
    </div>

  </section>

  <!-- Section - Car pictures -->
  <section class="section section--no-margin pictures vehicle-details__pictures">
    <div class="pictures__main">
      <img [src]="images[currentImasgeSelected]" />
    </div>
    <div class="pictures__thumbnails">
      <div *ngFor="let picture of images; let i = index" class="pictures__thumbnails__item"
        [class.pictures__thumbnails__item--active]="currentImasgeSelected === i">
        <img [src]="picture" (click)="onClickCarThumbnail(i)" />
      </div>
    </div>
  </section>

  <!-- Section - Car description -->
  <section class="section">
    <h2 class="section__title">Description</h2>
    <div class="section__card">
      <p [innerHtml]="vehicle.description[translate.currentLang] | safeHtml"></p>
    </div>
  </section>

  <!-- Section - Car rating -->
  <section class="section">
    <h2 class="section__title">{{ 'client.ads.kiwiz_opinion' | translate }}</h2>
    <div class="section__card">
      <div class="text-item">
        <label class="text-item__label">
          {{ 'admin.vehicles.modal.reliability' | translate }}
        </label>
        <app-kiwi-rating [rating]="vehicle.reliability">
        </app-kiwi-rating>
      </div>

      <div class="text-item">
        <label class="text-item__label">
          {{ 'admin.vehicles.modal.money_value' | translate }}
        </label>
        <app-kiwi-rating [rating]="vehicle.money_value">
        </app-kiwi-rating>
      </div>

      <div class="text-item">
        <label class="text-item__label">
          {{ 'admin.vehicles.modal.comfort' | translate }}
        </label>
        <app-kiwi-rating [rating]="vehicle.comfort">
        </app-kiwi-rating>
      </div>

      <div class="text-item">
        <label class="text-item__label">
          {{ 'admin.vehicles.modal.fun_factor' | translate }}
        </label>
        <app-kiwi-rating [rating]="vehicle.fun_factor">
        </app-kiwi-rating>
      </div>

      <div class="text-item">
        <label class="text-item__label">
          {{ 'admin.vehicles.modal.security' | translate }}
        </label>
        <app-kiwi-rating [rating]="vehicle.security">
        </app-kiwi-rating>
      </div>
    </div>
  </section>

  <!-- Section - Car features -->
  <section class="section">
    <h2 class="section__title">{{ 'client.ads.technical_sheet' | translate }}</h2>
    <div class="section__card">
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.make' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.make || '--' }}
        </p>
      </div>
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.model' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.model || '--' }}
        </p>
      </div>
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.drivetrain' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.drivetrain[translate.currentLang] || '--' }}
        </p>
      </div>
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.fuel' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.fuel[translate.currentLang] || '--' }}
        </p>
      </div>
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.recommended_drivetrain' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.recommended_drivetrain[translate.currentLang] || '--' }}
        </p>
      </div>
      <div class="text-item">
        <label class="text-item__label">
          {{ 'client.ads.consumption' | translate }}
        </label>
        <p class="text-item__content">
          {{ vehicle.consumption[translate.currentLang] || '--' }}
        </p>
      </div>
    </div>
  </section>

  <!-- Section - Car Stats -->
  <!-- <section *ngIf="vehicle.avg_stats">
    <section class="section">
      <h2 class="section__title">{{ 'client.ads.price_and_kilometers' | translate }}</h2>
      <div class="sectionCustom">
        <table>
          <tr class="tableTitle">
            <td>{{ 'client.ads.year' | translate }}</td>
            <td>{{ 'client.ads.average_price' | translate }}</td>
            <td>{{ 'client.ads.average_kilometers' | translate }}</td>
          </tr>
          <ng-container *ngIf="vehicle.avg_stats.length > 1">
            <tr class="tableContent" *ngFor="let item of vehicle.avg_stats">
              <td>{{item.year}}</td>
              <td>{{item.avg_price.toLocaleString()}} $</td>
              <td>{{item.avg_mileage.toLocaleString()}} km</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </section>
  </section> -->

</section>