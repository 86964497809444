<div class="content">
    <!-- <a routerLink="/">{{ 'general.return-to-site' | translate }}</a> -->
    <div class="c-logo">
        <img src="/assets/logo.png" alt="">
    </div>
    <div class="c-title">
        <h2>{{ 'errors.page-not-found.title' | translate }}</h2>
        <p>{{ 'errors.page-not-found.body' | translate }}</p>
    </div>
    <div class="redirect-buttons">
        <a href="https://www.kiwiz.ca">
            <button>{{ 'errors.redirect.website' | translate }}</button>
        </a>
        <button routerLink="/client/recherche">{{ 'errors.redirect.search' | translate }}</button>
        <button routerLink="/client/inspection">{{ 'errors.redirect.inspection' | translate }}</button>
    </div>
    <div class="c-graphic">
        <img src="/assets/404.png" alt="">
    </div>
</div>