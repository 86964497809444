import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'safeTranslate'
})
export class SafeTranslate implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(values?: string[] | string, searchKey?: string) {
    if (values == null) {
      return undefined;
    }

    if (values.includes(',')) {
      values = values.toString().split(', ');
    } else if (values[0] && values[0].length > 0 && values[0].includes(',')) {
      values = values[0].split(', ');
    }

    if (typeof values == 'string') {
      return this.translateComment(searchKey ? searchKey + values : values);
    }
    return values.map(value => this.translateComment(searchKey ? searchKey + value : value)).join(', ');
  }

  public translateComment(comment?: string) {
    if (!comment || comment.includes('undefined')) {
      return undefined;
    }

    let translatedComment = this.translate.instant(comment);
    
    let commentKey: string = '';
    if (translatedComment === comment) {
      commentKey = comment.split(' ').join('_');
      commentKey = commentKey.split('\'').join('_');
      translatedComment = this.translate.instant(commentKey);
    }

    return translatedComment === commentKey ? comment.slice(comment.lastIndexOf('.') + 1) : translatedComment;
  }

  /*
  public translateArray(values: string[]) {
    return values.slice(2).map(value => this.translateComment(value)).join(', ');
  }
  */
}
