<div class="modal-head">

    <div class="modal-head-title">
        <h2 class="u-h5">{{ 'general.welcome' | translate }}</h2>
    </div>

</div>

<div class="content">
    <p [innerHtml]="'general.display_name_message' | translate"></p>

    <input type="text" [(ngModel)]="firstName" [placeholder]="'general.firstName' | translate">
    <input type="text" [(ngModel)]="lastName" [placeholder]="'general.lastName' | translate">
</div>

<div class="modal-footer">
    <button class="o-button -secondary -large -outline" [disabled]="!canUpdate()" (click)="updateProfile()">
        {{ 'general.here_you_go' | translate }}</button>
</div>