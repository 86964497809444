import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomFile } from '../entities/custom_file';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  adminFileUrl = environment.apiUrl + '/admin/files/';
  publicFileUrl = environment.apiUrl + '/files/';
  inspectorFileUrl = environment.apiUrl + '/inspector/files/';

  constructor(private auth: AuthService, private http: HttpClient) { }

  async uploadFile(customFile: CustomFile, role: String): Promise<string> {
    const url = `${environment.apiUrl}/${role}/files/`;
    const formData: FormData = new FormData();
    formData.append("file", customFile.file, customFile.file.name);
    return firstValueFrom(this.http.post<string>(url, formData)).catch(error => {
      throw error;
    });
  }

  async getFile(file_uuid: string): Promise<CustomFile> {
    return firstValueFrom(this.http.get<CustomFile>(this.publicFileUrl + 'meta/' + file_uuid)).catch(error => {
      throw error;
    });
  }

  async deleteFile(file_uuid: string): Promise<string> {
    return firstValueFrom(this.http.delete<string>(this.adminFileUrl + file_uuid)).catch(error => {
      throw error;
    });
  }

  async generatePdf(htmlContent: string): Promise<Blob> {
    const url = `${this.publicFileUrl}/generate-pdf`;
    return firstValueFrom(this.http.post(url, { htmlContent }, { responseType: 'blob' })).catch(error => {
      throw error;
    });
  }
}
