import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe returns part of a translation that contains a link.
 * The index specifies which part will be returned.
 */
@Pipe({
  name: 'translateWithLink'
})
export class TranslateWithLinkPipe implements PipeTransform {

  /**
   * Instantiates the class.
   * @param translate The translate service.
   */
  constructor(
    private translate: TranslateService
  ) { }

  /**
   * Returns a substring of a translation corresponding to the given index.
   * @param value The translation key.
   * @param index The translation index to return.
   * @returns The substring corresponding to the given index.
   */
  transform(value: string, index: number) {
    return this.translate.instant(value).split('|')[index];
  }
}
