
<div class="modal-head">

<div class="modal-head-title">
    <h3>{{ 'authentication.password_reset_successful' | translate }}</h3>
</div>

</div>
<div class="content">

    <button class="-secondary"  routerLink="/" mat-dialog-close> <!-- Redirects to the login page-->
        {{ 'general.continue' | translate }}</button>
</div>
