<!------------------------------------------------------ INFORMATIONS ----------------------------------------->
<div class="container-full" #information *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div class="container-full-box" >
  
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_synthesis.information_on_vehicle"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <div class="container-half-esthetics" style="border: none">
      <div class="container-half-box-esthetics">
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.brand"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.identification?.make }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.model"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.identification?.model }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.version"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.identification?.version }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.year"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.identification?.year }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.mileage"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.mileage }} </span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.vin"
          | safeTranslate
          }}
          :
          <span>{{
            loadedInspection.data?.identification?.niv?.toUpperCase()
            }}</span>
        </p>
        <p *ngIf="loadedInspection.data?.identification?.vehicleColor">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.color"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.data?.identification?.vehicleColor
            | safeTranslate: 'client.inspections.reports.inspection_details.tab_synthesis.' }}</span>
        </p>
        <p *ngIf="loadedInspection.data?.identification?.vehicleInteriorColor">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.interiorColor"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.data?.identification?.vehicleInteriorColor
            | safeTranslate: 'client.inspections.reports.inspection_details.tab_synthesis.' }}</span>
        </p>
        <p *ngIf="loadedInspection.data?.identification?.vehicleInteriorFinish">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.interiorFinish"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.data?.identification?.vehicleInteriorFinish
            | safeTranslate: 'client.inspections.reports.inspection_details.tab_synthesis.' }}</span>
        </p>
      </div>

      <div id="line" class="container-half-box-esthetics" style="border-left: 2px solid rgb(214, 214, 214); padding: 0">
      </div>

      <div id="interior_box" class="container-half-box-esthetics">
        <p *ngIf="loadedInspection.data?.identification?.vehicleFuel">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.fuel"
          | safeTranslate
          }}
          :
          <span>{{
            "dashboard.fuelType." +
            loadedInspection.data?.identification?.vehicleFuel?.toLowerCase()
            | translate
            }}</span>
        </p>

        <p *ngIf="loadedInspection.data?.identification?.motor">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.motor"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.data?.identification?.motor?.toLowerCase() }}</span>
        </p>

        <p *ngIf="loadedInspection.data?.identification?.vehicleTransmission">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.transmission"
          | safeTranslate
          }}
          :
          <span>{{
            loadedInspection.data?.identification?.vehicleTransmission
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>

        <p *ngIf="loadedInspection.data?.identification?.vehicleMotricityType">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.motricity"
          | safeTranslate
          }}
          :
          <span>{{
            loadedInspection.data?.identification?.vehicleMotricityType
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>

        <p *ngIf="loadedInspection.data?.identification?.firstHandVehicle">
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.firstHand"
          | safeTranslate
          }}
          :
          <span>{{
            loadedInspection.data?.identification?.firstHandVehicle
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>

        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.date_of_inspection"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.date | date: "yyyy/dd/MM" }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.time_of_inspection"
          | safeTranslate
          }}
          :
          <span>{{ loadedInspection.date | date: "HH:mm" }}</span>
        </p>
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_synthesis.city"
          | safeTranslate
          }}
          : <span>{{ loadedInspection.data?.identification?.city }}</span>
        </p>
      </div>
    </div>
  </div>
</div>


<!------------------------------------------------------ESSAI ROUTIER----------------------------------------->

<div class="container-full" #essaiRoutier *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div class="container-full-box">
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_details.road_test"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />

    <p *ngIf="loadedInspection.data?.roadTestInfo &&
      (loadedInspection.data?.roadTestInfo?.[0] =='Mauvais' || loadedInspection.data?.roadTestInfo === 'Non-réalisable' ||
      loadedInspection.data?.roadTestInfo === 'Impossible')" class="warning">
      {{
      "client.inspections.reports.inspection_details.tab_details.not_feasible_comment"
      | safeTranslate
      }}
      <span *ngIf="
          arrayObject.isArray(loadedInspection.data?.roadTestDamage);
          else roadTestDamageTemplate
        ">
        {{loadedInspection.data?.roadTestDamage?.[0]}}
        <span *ngIf="loadedInspection.data?.roadTestDamage?.[1]">
          - {{loadedInspection.data?.roadTestDamage?.[1]}}
        </span>
      </span>
      <ng-template #roadTestDamageTemplate>
        <span>
          {{ loadedInspection.data?.roadTestDamage }}
          <span *ngIf="loadedInspection.data?.roadTestDamageOther">- {{ loadedInspection.data?.roadTestDamageOther
            }}</span>
        </span>
      </ng-template>
    </p>

    <table id="default_table">
      <colgroup></colgroup>
      <tr>
        <th style="width: 450px">
          {{
          "client.inspections.reports.inspection_details.tab_details.elements"
          | safeTranslate
          }}
        </th>
        <th id="mobile_comment">
          {{
          "client.inspections.reports.inspection_details.tab_details.comment"
          | safeTranslate
          }}
        </th>
        <th style="width: 20px">Condition</th>
      </tr>
      <tr *ngIf="loadedInspection.data?.goodStart" [ngSwitch]="loadedInspection.data?.goodStart?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.goodStart"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="['Non', 'No'].includes(loadedInspection.data?.goodStart ?.[0] ?? '')" style="border: none; background: none">
            {{ loadedInspection.data?.goodStart?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <!-- Exception to condition template -->
        <td *ngSwitchCase="'Oui'" style="text-align: center;">✔️</td>
        <td *ngSwitchCase="'Yes'" style="text-align: center;">✔️</td>
        <td *ngSwitchCase="'Non'" style="text-align: center;">❌</td>
        <td *ngSwitchCase="'No'" style="text-align: center;">❌</td>
      </tr>
      <tr *ngIf="loadedInspection.data?.constantAccelerationRoadTest">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.linear_acceleration"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'constantAccelerationRoadTest' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.constantAccelerationRoadTest?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.motorIdle" [ngSwitch]="loadedInspection.data?.motorIdle?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.motorIdle"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="['Non', 'No'].includes(loadedInspection.data?.motorIdle?.[0] ?? '')" style="border: none; background: none">
            {{ loadedInspection.data?.motorIdle?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <!-- Exception to condition template -->
        <td *ngSwitchCase="'Oui'" style="text-align: center;">✔️</td>
        <td *ngSwitchCase="'Yes'" style="text-align: center;">✔️</td>
        <td *ngSwitchCase="'Non'" style="text-align: center;">❌</td>
        <td *ngSwitchCase="'No'" style="text-align: center;">❌</td>
      </tr>
      <tr *ngIf="loadedInspection.data?.normalPressureTempRoadTest?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.temperature"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'normalPressureTempRoadTest' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.normalPressureTempRoadTest?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.goodLineKeeping" [ngSwitch]="loadedInspection.data?.goodLineKeeping?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.driving_line"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'goodLineKeeping' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.goodLineKeeping?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.directionProperFunctioning?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.function_direction"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'directionProperFunctioning' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.directionProperFunctioning?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.smoothGearShift">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.easy_shifting"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'smoothGearShift' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.smoothGearShift?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.goodClutch">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.clutch_grip"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'goodClutch' }
          ">
        </ng-container>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.goodClutch?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.loudTransmission?.[0]">
        <td id="mobile_comment">
          {{
          "client.inspections.reports.inspection_details.tab_details.loudTransmission"
          | safeTranslate
          }}
        </td>
        <td>
          <!-- No comment, yes or no field -->
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.loudTransmission?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.loudDifferential?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.loudDifferential"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <!-- No comment, yes or no field -->
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.loudDifferential?.[0]}">
        </ng-container>
      </tr>
      <!-- Old since 2022-07-07, left for old support -->
      <tr *ngIf="loadedInspection.data?.goodSuspension">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.running_gear"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'goodClutch' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.goodSuspension?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="
          loadedInspection.data?.frontSuspension &&
          loadedInspection.data?.rearSuspension
        ">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.running_gear"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment"
          [class.warning]="inspection$.value?.data?.['frontSuspensionDamageLevel'] === 'Major' || inspection$.value?.data?.['frontSuspensionDamageLevel'] === 'Majeur' ||
            inspection$.value?.data?.['rearSuspensionDamageLevel'] === 'Major' || inspection$.value?.data?.['rearSuspensionDamageLevel'] === 'Majeur'">
          <ng-container *ngIf="inspection$.value?.data?.['frontSuspension']?.[0] !== 'Excellent'">
            <span
              *ngIf="inspection$.value?.data?.['frontSuspension']?.[0] !== 'Mauvais' && inspection$.value?.data?.['frontSuspension']?.[0] !== 'Bon'">{{
              inspection$.value?.data?.['frontSuspension']?.[0] | safeTranslate: 'inspection.comments.' }}
              :
            </span>
            <span>{{ inspection$.value?.data?.['frontSuspension']?.slice(1)?.join(', ') | safeTranslate:
              'inspection.comments.' }}</span>
            <span> {{ "front" | safeTranslate: "inspection.comments." }}</span>
            <span *ngIf="inspection$.value?.data?.['frontSuspension' + 'DamageLeftRight']">
              {{ inspection$.value?.data?.['frontSuspension' + 'DamageLeftRight']?.toLowerCase() | safeTranslate:
              'inspection.comments.' }}
            </span>
            <span *ngIf="inspection$.value?.data?.['frontSuspension' + 'DamageLevel']">
              ({{ inspection$.value?.data?.['frontSuspension' + 'DamageLevel']?.toLowerCase() | safeTranslate:
              'inspection.comments.'

              }})</span>
          </ng-container>
          <br
            *ngIf="inspection$.value?.data?.['frontSuspension']?.[0] !== 'Excellent' || inspection$.value?.data?.['rearSuspension']?.[0] !== 'Excellent'" />
          <ng-container *ngIf="inspection$.value?.data?.['rearSuspension']?.[0] !== 'Excellent'">
            <span
              *ngIf="inspection$.value?.data?.['rearSuspension']?.[0] !== 'Mauvais' && inspection$.value?.data?.['rearSuspension']?.[0] !== 'Bon'">{{
              inspection$.value?.data?.['rearSuspension']?.[0] | safeTranslate: 'inspection.comments.' }}
              :
            </span>
            <span>{{ inspection$.value?.data?.['rearSuspension']?.slice(1)?.join(', ') | safeTranslate:
              'inspection.comments.' }}</span>
            <span *ngIf="inspection$.value?.data?.['rearSuspension' + 'DamageFrontrear']">
              {{ inspection$.value?.data?.['rearSuspension' + 'DamageFrontrear'].join(' ')?.toLowerCase() |
              safeTranslate: 'inspection.comments.' }}</span>
            <span> {{ "rear" | safeTranslate: "inspection.comments." }} </span>
            <span *ngIf="inspection$.value?.data?.['rearSuspension' + 'DamageLevel']">
              ({{ inspection$.value?.data?.['rearSuspension' + 'DamageLevel']?.toLowerCase() | safeTranslate:
              'inspection.comments.'

              }})</span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: findWorstRoadTestState([loadedInspection.data?.frontSuspension?.[0], loadedInspection.data?.rearSuspension?.[0]])}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.engineVibration">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.motor_vibration"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'engineVibration' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.engineVibration?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.brakeNoice">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.brake_noises"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'brakeNoice' }
          ">
        </ng-container>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.brakeNoice?.[0]}">
        </ng-container>
      </tr>
      <tr *ngIf="loadedInspection.data?.cruiseControl">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.cruiseControl"
          | safeTranslate
          }}
        </td>
        <ng-container *ngTemplateOutlet="
            roadTestCommentTemplate;
            context: { field: 'cruiseControl' }
          ">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.cruiseControl?.[0]}">
        </ng-container>
      </tr>
    </table>

    <p *ngIf="loadedInspection.data?.roadTestNotVerifiedReason">
      {{
      "client.inspections.reports.inspection_details.tab_details.not_verified_comment"
      | safeTranslate
      }}

      <span>
        {{ loadedInspection.data?.roadTestNotVerifiedReason }}
      </span>
    </p>
  </div>
</div>

<!----------------------------- ELECTRIQUE ----------------------------------------->
<div class="container-full" *ngIf="(inspection$ | async) as loadedInspection; else loadingTemplate">
  <div class="container-full-box electric-container"
    *ngIf="['Electrique', 'Electric', 'Hybride Branchable', 'Plug-In Hybrid'].includes(loadedInspection.data?.vehicleFuel ?? '')">
    <div style="border: 0px;">
      <h2>{{ 'client.inspections.reports.inspection_details.tab_details.electricSystem' | safeTranslate }}</h2>
      <hr class="solid">

      <div class="container-half-esthetics" style="border: none;">
        <!-- Connecteurs -->
        <div class="container-half-box-esthetics">
          <ng-container *ngIf="loadedInspection.data?.electricalConnectorType">
            <ng-container *ngTemplateOutlet="electricalConnectorTemplate; context: {field: 'electricalConnector'}">
            </ng-container>
          </ng-container>

          <ng-container *ngIf="loadedInspection.data?.secondElectricalConnectorExists">
            <ng-container
              *ngTemplateOutlet="electricalConnectorTemplate; context: {field: 'secondElectricalConnector'}">
            </ng-container>
          </ng-container>

          <div id="line" class="container-half-box-esthetics"
            style="border-left: 2px solid rgb(214, 214, 214); padding: 0;">
          </div>
        </div>

        <div id="line" class="container-half-box-esthetics"
          style="border-left: 2px solid rgb(214, 214, 214); padding: 0;">
        </div>

        <!-- Information -->
        <div id="interior_box" class="container-half-box-esthetics">
          <table id="default_table">
            <tr>
              <th style="width: 200px;">{{ 'client.inspections.reports.inspection_details.tab_details.elements' |
                safeTranslate }}</th>
              <th id="mobile_comment">{{ 'client.inspections.reports.inspection_details.tab_details.comment' |
                safeTranslate
                }}</th>
              <th style="width: 20px;">Condition</th>
            </tr>

            <tr *ngIf="loadedInspection.data?.homeChargerWorking && loadedInspection.data?.homeChargerWorking?.[0]"
              [ngSwitch]="loadedInspection.data?.homeChargerWorking">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.home_charger_working' | safeTranslate }}
              </td>
              <td id="mobile_comment">
                <div *ngIf="loadedInspection.data?.homeChargerWorking?.[0] != 'Excellent'"
                  style="border: none; background: none;">
                  {{ loadedInspection.data?.homeChargerConditionOther }}
                </div>
              </td>
              <!-- Exception to condition template -->
              <td *ngSwitchCase="'Oui'" style="text-align: center;">✔️</td>
              <td *ngSwitchCase="'Yes'" style="text-align: center;">✔️</td>
              <td *ngSwitchCase="'Non'" style="text-align: center;">❌</td>
              <td *ngSwitchCase="'No'" style="text-align: center;">❌</td>
              <ng-container *ngSwitchDefault>
                <ng-container
                  *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.homeChargerWorking?.[0]}">
                </ng-container>
              </ng-container>
            </tr>

            <tr *ngIf="loadedInspection.data?.homeCharger && loadedInspection.data?.homeCharger?.[0]">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.home_charger' | safeTranslate }}</td>
              <td id="mobile_comment">
                <ng-container *ngFor="let comment of loadedInspection.data?.homeCharger; let i = index">
                  <span *ngIf="i != 0">
                    <div *ngIf="loadedInspection.data?.homeCharger?.[0] != 'Excellent'"
                      style="border: none; background: none;">
                      {{loadedInspection.data?.homeCharger?.[i] | safeTranslate: 'inspection.comments.'}}<br>
                    </div>
                  </span>
                </ng-container>
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.homeCharger?.[0]}">
              </ng-container>
            </tr>

            <tr *ngIf="loadedInspection.data?.electricalConnector && loadedInspection.data?.electricalConnector?.[0]">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.electrical_connector' | safeTranslate }}
              </td>
              <td id="mobile_comment">
                <ng-container *ngFor="let comment of loadedInspection.data?.electricalConnector; let i = index">
                  <span *ngIf="i != 0">
                    <div *ngIf="loadedInspection.data?.electricalConnector?.[0] != 'Excellent'"
                      style="border: none; background: none;">
                      {{loadedInspection.data?.electricalConnector?.[i] | safeTranslate: 'inspection.comments.'}}<br>
                    </div>
                  </span>
                </ng-container>
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.electricalConnector?.[0]}">
              </ng-container>
            </tr>

            <tr *ngIf="loadedInspection.data?.motorManagement && loadedInspection.data?.motorManagement?.[0]">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.motorManagement' | safeTranslate }}</td>
              <ng-container *ngTemplateOutlet="roadTestCommentTemplate; context: {field: 'motorManagement'}">
              </ng-container>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.motorManagement?.[0]}">
              </ng-container>
            </tr>

            <tr *ngIf="loadedInspection.data?.regenBrakingRoadTest && loadedInspection.data?.regenBrakingRoadTest?.[0]">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.regenBraking' | safeTranslate }}</td>
              <ng-container *ngTemplateOutlet="roadTestCommentTemplate; context: {field: 'regenBrakingRoadTest'}">
              </ng-container>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.brakeFluidLevel?.[0]}">
              </ng-container>
            </tr>

            <tr *ngIf="loadedInspection.data?.maxBatteryCapacity">
              <td>{{ 'client.inspections.reports.inspection_details.tab_details.maxBatteryCapacity' | safeTranslate }}
              </td>
              <td id="mobile_comment">
                <div style="border: none; background: none;">
                  {{loadedInspection.data?.maxBatteryCapacity}} %
                </div>
              </td>
              <ng-container
                *ngIf="int(loadedInspection.data?.maxBatteryCapacity ?? 0) >= 90; else correctIndicatorTemplate">
                <td style="text-align: center;">✔️</td>
              </ng-container>
              <ng-template #correctIndicatorTemplate>
                <ng-container
                  *ngIf="int(loadedInspection.data?.maxBatteryCapacity ?? 0) >= 80; else badIndicatorTemplate">
                  <td style="text-align: center;">💬</td>
                </ng-container>
              </ng-template>
              <ng-template #badIndicatorTemplate>
                <td style="text-align: center;">❌</td>
              </ng-template>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!---------------------------------------------- MÉCANIQUE ----------------------------------------->
<div class="container-full" #mecanique *ngIf="(inspection$ | async) as loadedInspection; else loadingTemplate">
  <div class="container-full-box"
    *ngIf="!['Electrique', 'Electric', 'Hybride Branchable', 'Plug-In Hybrid'].includes(loadedInspection.data?.vehicleFuel ?? '')">
    <div style="border: 0px;">
      <h2>{{ 'client.inspections.reports.inspection_details.tab_details.engine' | safeTranslate }}</h2>
      <hr class="solid">
      <table id="default_table">
        <tr>
          <th style="width: 20%">
            {{
            "client.inspections.reports.inspection_details.tab_details.elements"
            | safeTranslate
            }}
          </th>
          <th  id="mobile_comment">
            {{
            "client.inspections.reports.inspection_details.tab_details.comment"
            | safeTranslate
            }}
          </th>
          <th style="width: 20px">Condition</th>
        </tr>

        <tr *ngIf="['Hybride', 'Hybrid'].includes(loadedInspection.data?.vehicleFuel ?? '') &&
            loadedInspection.data?.motorManagement && loadedInspection.data?.motorManagement?.[0]" class="electric">
          <td>{{ 'client.inspections.reports.inspection_details.tab_details.motorManagement' | safeTranslate }}</td>
          <ng-container *ngTemplateOutlet="roadTestCommentTemplate; context: {field: 'motorManagement'}">
          </ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.motorManagement?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="['Hybride', 'Hybrid'].includes(loadedInspection.data?.vehicleFuel ?? '') &&
            loadedInspection.data?.regenBrakingRoadTest && loadedInspection.data?.regenBrakingRoadTest?.[0]"
          class="electric">
          <td>{{ 'client.inspections.reports.inspection_details.tab_details.regenBraking' | safeTranslate }}</td>
          <ng-container *ngTemplateOutlet="roadTestCommentTemplate; context: {field: 'regenBrakingRoadTest'}">
          </ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.regenBrakingRoadTest?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="
            ['4 roues motrices', '4 wheel drive'].includes(
              loadedInspection.data?.identification?.vehicleMotricityType ?? ''
            ) && loadedInspection.data?.vehicleMotricity
          ">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.four_wheel_drive"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.vehicleMotricity?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.vehicleMotricity?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.vehicleMotricity?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.exhaustSystem && loadedInspection.data?.exhaustSystem?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.exhaust_system"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.exhaustSystem?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.exhaustSystem?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.exhaustSystem?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.homeChargerWorking && loadedInspection.data?.homeChargerWorking?.[0]"
          [ngSwitch]="loadedInspection.data?.homeChargerWorking">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.home_charger_working"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.homeChargerWorking?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.homeChargerConditionOther }}
            </div>
          </td>
          <!-- Exception to condition template -->
          <td *ngSwitchCase="'Oui'" style="text-align: center">✔️</td>
          <td *ngSwitchCase="'Yes'" style="text-align: center">✔️</td>
          <td *ngSwitchCase="'Non'" style="text-align: center">❌</td>
          <td *ngSwitchCase="'No'" style="text-align: center">❌</td>
          <ng-container *ngSwitchDefault>
            <ng-container
              *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.homeChargerWorking?.[0]}">
            </ng-container>
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.homeCharger && loadedInspection.data?.homeCharger?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.home_charger"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <ng-container *ngFor="
                let comment of loadedInspection.data?.homeCharger;
                let i = index
              ">
              <span *ngIf="i != 0">
                <div *ngIf="loadedInspection.data?.homeCharger?.[0] != 'Excellent'"
                  style="border: none; background: none">
                  {{loadedInspection.data?.homeCharger?.[i] | safeTranslate: 'inspection.comments.'

                  }}<br />
                </div>
              </span>
            </ng-container>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.homeCharger?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.electricalConnector && loadedInspection.data?.electricalConnector?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.electrical_connector"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <ng-container *ngFor="
                let comment of loadedInspection.data?.electricalConnector;
                let i = index
              ">
              <span *ngIf="i != 0">
                <div *ngIf="loadedInspection.data?.electricalConnector?.[0] != 'Excellent'"
                  style="border: none; background: none">
                  {{loadedInspection.data?.electricalConnector?.[i] | safeTranslate: 'inspection.comments.'

                  }}<br />
                </div>
              </span>
            </ng-container>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.electricalConnector?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.accessoryBelt && loadedInspection.data?.accessoryBelt?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.accesory_belt"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <ng-container *ngFor="
                let comment of loadedInspection.data?.accessoryBelt;
                let i = index
              ">
              <span *ngIf="i != 0">
                <div *ngIf="loadedInspection.data?.accessoryBelt?.[0] != 'Excellent'"
                  style="border: none; background: none">
                  {{loadedInspection.data?.accessoryBelt?.[i] | safeTranslate: 'inspection.comments.'}}
                  <br />
                </div>
              </span>
            </ng-container>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.accessoryBelt?.[0]}">
          </ng-container>
        </tr>

        <!-- batteryCondition : old field's name : kept to support old version
          now : battery (only)
          since 03/2022, v2.0
        -->
        <tr *ngIf="loadedInspection.data?.batteryCondition && loadedInspection.data?.batteryCondition?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.battery"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.batteryCondition?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.batteryCondition?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.batteryCondition?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.battery && loadedInspection.data?.battery?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.battery"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.battery?.[0] != 'Excellent'" style="border: none; background: none">
              {{ loadedInspection.data?.battery?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.battery?.[0]}">
          </ng-container>
        </tr>

        <tr>
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.windshield_washer_fluid"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.wiperFluidLevel?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.wiperFluidLevel?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.wiperFluidLevel?.[0]}">
          </ng-container>
        </tr>
        <tr>
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.brake_fluid"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.brakeFluidLevel?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.brakeFluidLevel?.[1] | safeTranslate: 'inspection.comments.'

              }},
              {{ loadedInspection.data?.brakeFluidLevel?.[2] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.brakeFluidLevel?.[0]}">
          </ng-container>
        </tr>
        <tr *ngIf="loadedInspection.data?.powerSteeringFluidLevel">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.powerSteering_fluid"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.powerSteeringFluidLevel?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.powerSteeringFluidLevel?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.powerSteeringFluidLevel?.[0]}">
          </ng-container>
        </tr>
        <tr *ngIf="loadedInspection.data?.coolantLevel">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.coolant_fluid"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.coolantLevel?.[0] != 'Excellent'" style="border: none; background: none">
              {{ loadedInspection.data?.coolantLevel?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.coolantLevel?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.motorOilLevel">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.engine_oil_level"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <div *ngIf="loadedInspection.data?.motorOilLevel?.[0] != 'Excellent'"
              style="border: none; background: none">
              {{ loadedInspection.data?.motorOilLevel?.[1] | safeTranslate: 'inspection.comments.'}}
            </div>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.motorOilLevel?.[0]}">
          </ng-container>
        </tr>
        <tr *ngIf="loadedInspection.data?.isOBDScanner">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.obd_scanner"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="loadedInspection.data?.isOBDScanner?.[0] == 'Mauvais'">
              {{ loadedInspection.data?.isOBDScanner?.[1] | safeTranslate: 'inspection.comments.'

              }}, code(s):
              {{ loadedInspection.data?.isOBDScannerOther }}
            </span>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.isOBDScanner?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.airFilter">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.air_filters"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="loadedInspection.data?.airFilter?.[0] != 'Excellent' ">
              {{ loadedInspection.data?.airFilter?.[1] | safeTranslate: 'inspection.comments.' }}
            </span>
          </td>
          <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.airFilter?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.radiatorHose && loadedInspection.data?.radiatorHose?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.radiatorHose"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="['Mauvais', 'Bad'].includes(loadedInspection.data?.radiatorHose?.[0] ?? '')">
              {{ loadedInspection.data?.radiatorHose?.[1] }}
            </span>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.radiatorHose?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.radiator && loadedInspection.data?.radiator?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.radiator"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="['Mauvais', 'Bad'].includes(loadedInspection.data?.radiator?.[0] ?? '')">
              {{ loadedInspection.data?.radiator?.[1] }}
            </span>
          </td>
          <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.radiator?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.chargingSystemHealth && loadedInspection.data?.chargingSystemHealth?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.chargingSystem"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="['Mauvais', 'Bad'].includes(loadedInspection.data?.chargingSystemHealth?.[0] ?? '')">
              {{ loadedInspection.data?.chargingSystemHealth?.[1] }}
            </span>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.chargingSystemHealth?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.abnormalSmoke && loadedInspection.data?.abnormalSmoke?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.abnormalSmoke"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="['Yes', 'Oui'].includes(loadedInspection.data?.abnormalSmoke?.[0] ?? '')">
              {{ loadedInspection.data?.abnormalSmoke?.[1] }}
            </span>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.abnormalSmoke?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.handBrake && loadedInspection.data?.handBrake?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.handBrake"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="['Yes', 'Oui'].includes(loadedInspection.data?.handBrake?.[0] ?? '')">
              {{ loadedInspection.data?.handBrake?.[1] }}
            </span>
          </td>
          <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.handBrake?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.visibleEngineLeak && loadedInspection.data?.visibleEngineLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.visibleEngineLeak"
            | safeTranslate
            }}
          </td>
          <ng-container *ngTemplateOutlet="
              leakCommentTemplate;
              context: { field: 'visibleEngineLeak' }
            "></ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.visibleEngineLeak?.[0]}">
          </ng-container>
        </tr>

        <tr
          *ngIf="loadedInspection.data?.visibleTransmissionLeak && loadedInspection.data?.visibleTransmissionLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.visibleTransmissionLeak"
            | safeTranslate
            }}
          </td>
          <ng-container *ngTemplateOutlet="
              leakCommentTemplate;
              context: { field: 'visibleTransmissionLeak' }
            "></ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.visibleTransmissionLeak?.[0]}">
          </ng-container>
        </tr>

        <tr
          *ngIf="loadedInspection.data?.visibleDifferentialLeak && loadedInspection.data?.visibleDifferentialLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.visibleDifferentialLeak"
            | safeTranslate
            }}
          </td>
          <ng-container *ngTemplateOutlet="
              leakCommentTemplate;
              context: { field: 'visibleDifferentialLeak' }
            "></ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.visibleDifferentialLeak?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.brakeSystemLeak && loadedInspection.data?.brakeSystemLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.brakeSystemLeak"
            | safeTranslate
            }}
          </td>
          <ng-container *ngTemplateOutlet="
              leakCommentTemplate;
              context: { field: 'brakeSystemLeak' }
            "></ng-container>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.brakeSystemLeak?.[0]}">
          </ng-container>
        </tr>

        <tr *ngIf="loadedInspection.data?.visibleShockAbsorbersLeak && loadedInspection.data?.visibleShockAbsorbersLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.visibleShockAbsorbersLeak"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment" [class.warning]="inspection$.value?.data?.['visibleShockAbsorbersLeakDamageLevel'] === 'Major' ||
              inspection$.value?.data?.['visibleShockAbsorbersLeakDamageLevel'] === 'Majeur'">
            <ng-container *ngIf="['Yes', 'Oui'].includes(inspection$.value?.data?.visibleShockAbsorbersLeak?.[0] ?? '')">
              <span *ngIf="inspection$.value?.data?.['visibleShockAbsorbersLeakFrontBack']">
                {{ getVisibleShockAbsorbersLeakConditions(inspection$.value?.data?.['visibleShockAbsorbersLeakFrontBack']) | safeTranslate: 'inspection.comments.' }}
              </span>
            </ng-container>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.visibleShockAbsorbersLeak?.[0]}">
          </ng-container>
        </tr>

        <!-- Not used anymore, but kept to support old versions -->
        <tr *ngIf="loadedInspection.data?.visibleLeak && loadedInspection.data?.visibleLeak?.[0]">
          <td>
            {{
            "client.inspections.reports.inspection_details.tab_details.visible_leak"
            | safeTranslate
            }}
          </td>
          <td id="mobile_comment">
            <span *ngIf="loadedInspection.data?.visibleLeak?.[0] == 'Mauvais'">
              {{ loadedInspection.data?.visibleLeak?.[1] }}
            </span>
          </td>
          <ng-container
            *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.visibleLeak?.[0]}">
          </ng-container>
        </tr>
      </table>
    </div>
  </div>
</div>


<!-------------------------------------------- Lumieres ---------------------------------------->
<div class="container-full" #lumier *ngIf="(inspection$ | async) as loadedInspection; else loadingTemplate">
  <div class="container-full-box">
    <div style="border: 0px;">
      <h2>{{ 'client.inspections.reports.inspection_details.tab_details.lights' | safeTranslate }}</h2>
      <hr class="solid">
      <div class="container-half-functional" style="border: none">
        <div class="container-half-box-functional">
          <h3>
            {{
            "client.inspections.reports.inspection_details.tab_details.front"
            | safeTranslate
            }}
          </h3>
          <table id="default_table">
            <colgroup>
              <col span="1" width="1000px" />
            </colgroup>
            <tr>
              <th>
                {{
                "client.inspections.reports.inspection_details.tab_details.elements"
                | safeTranslate
                }}
              </th>
              <th>Condition</th>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.front_passing_lamps"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontPassingLamps?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.front_main_beam"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontMainBeam?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.front_head_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontHeadLights?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.fog_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontFogLights?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.turn_signal"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontFlashingSignalLamps?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.interior_light"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.insideLighting?.[0]}">
              </ng-container>
            </tr>
          </table>
        </div>

        <div class="container-half-box-functional">
          <h3>
            {{
            "client.inspections.reports.inspection_details.tab_details.rear"
            | safeTranslate
            }}
          </h3>
          <table id="default_table">
            <colgroup>
              <col span="1" width="1000px" />
            </colgroup>
            <tr>
              <th>
                {{
                "client.inspections.reports.inspection_details.tab_details.elements"
                | safeTranslate
                }}
              </th>
              <th>Condition</th>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.position_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.backPositionLamps?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.brake_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.brakeLights?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.reversing_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.reversingLamps?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.license_plate_lights"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.licensePlateLight?.[0]}">
              </ng-container>
            </tr>
            <tr>
              <td>
                {{
                "client.inspections.reports.inspection_details.tab_details.turn_signal"
                | safeTranslate
                }}
              </td>
              <ng-container
                *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.backFlashingSignalLamps?.[0]}">
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!------------------------------------------------------WHEELS----------------------------------------->
<div #wheels class="container-full" *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div class="container-full-box">
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_details.tires"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <div class="container-half-esthetics" style="border: none">
      <div class="container-half-box-esthetics">
        <!------------------------------------------------------------------------ TIRES------------------------------------------------------------------->
        <h3 *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.tireSet2Available ?? ''
            )
          " style="text-decoration: underline">
          {{
          "client.inspections.reports.inspection_details.tab_details.vehicle_tires"
          | safeTranslate
          }}
        </h3>
        <span>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.front_driver"
            | safeTranslate
            }}
            <br />
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameMakeModelDriverFrontTire ?? ''
                );
                else makeDriverFrontTire
              ">
              {{ loadedInspection.data?.makeModelDriverBackTire }}
            </span>
            <ng-template #makeDriverFrontTire>
              <span>{{ loadedInspection.data?.differentMakeModelDriverFrontTire }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameYearDriverFrontTire ?? ''
                );
                else oldWrongKey
              ">
              {{ loadedInspection.data?.driverBackTireYear }}
            </span>
            <ng-template #oldWrongKey>
              <span *ngIf="
                  ['Oui', 'Yes'].includes(
                    loadedInspection.data?.sameYearFrontTire ?? ''
                  );
                  else yearDriverFrontTire
                ">
                {{ loadedInspection.data?.driverBackTireYear }}
              </span>
            </ng-template>
            <ng-template #yearDriverFrontTire>
              <span>{{ loadedInspection.data?.differentYearDriverFrontTire }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameTypeDriverFrontTire ?? ''
                );
                else typeDriverFrontTire
              ">
              {{
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <ng-template #typeDriverFrontTire>
              <span>{{
                loadedInspection.data?.differentTypeDriverFrontTire
                | safeTranslate: "inspection.comments."
                }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameSizeDriverFrontTire ?? ''
                );
                else sizeDriverFrontTire
              ">{{ loadedInspection.data?.sizeDriverBackTire }}
            </span>
            <ng-template #sizeDriverFrontTire>
              <span>{{ loadedInspection.data?.differentSizeDriverFrontTire }}
              </span>
            </ng-template>
          </p>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.front_passenger"
            | safeTranslate
            }}
            <br />
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameMakeModelPassengerFrontTire ?? ''
                );
                else makePassengerFrontTire
              ">{{ loadedInspection.data?.makeModelDriverBackTire }}
            </span>
            <ng-template #makePassengerFrontTire>
              <span>{{
                loadedInspection.data?.differentMakeModelPassengerFrontTire
                }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameYearPassengerFrontTire ?? ''
                );
                else yearPassengerFrontTire
              ">{{ loadedInspection.data?.driverBackTireYear }}
            </span>
            <ng-template #yearPassengerFrontTire>
              <span>{{ loadedInspection.data?.differentYearPassengerFrontTire }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameTypePassengerFrontTire ?? ''
                );
                else typePassengerFrontTire
              ">
              {{
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <ng-template #typePassengerFrontTire>
              <span>{{
                loadedInspection.data?.differentTypePassengerFrontTire
                | safeTranslate: "inspection.comments."
                }}</span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameSizePassengerFrontTire ?? ''
                );
                else sizePassengerFrontTire
              ">{{ loadedInspection.data?.sizeDriverBackTire }}
            </span>
            <ng-template #sizePassengerFrontTire>
              <span>
                {{ loadedInspection.data?.differentSizePassengerFrontTire }}
              </span>
            </ng-template>
          </p>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.rear_driver"
            | safeTranslate
            }}
            <br />
            <span>{{ loadedInspection.data?.makeModelDriverBackTire }} </span>
            <span>{{ loadedInspection.data?.driverBackTireYear }} </span>
            <span>{{
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{ loadedInspection.data?.sizeDriverBackTire }}</span>
          </p>
          <p *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            {{
            "client.inspections.reports.inspection_details.tab_details.interior_rear_driver_side"
            | safeTranslate
            }}
            <br />
            <span>{{ loadedInspection.data?.makeModelDriverBackTire }} </span>
            <span>{{ loadedInspection.data?.driverBackTireYear }} </span>
            <span>{{
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{ loadedInspection.data?.sizeDriverBackTire }}</span>
          </p>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.rear_passenger"
            | safeTranslate
            }}
            <br />
            <span>{{
              loadedInspection.data?.differentMakeModelPassengerBackTire ||
              loadedInspection.data?.makeModelDriverBackTire
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentYearPassengerBackTire ||
              loadedInspection.data?.driverBackTireYear
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentTypePassengerBackTire ||
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentSizePassengerBackTire ||
              loadedInspection.data?.sizeDriverBackTire
              }}</span>
          </p>
          <p *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            {{
            "client.inspections.reports.inspection_details.tab_details.interior_rear_passenger_side"
            | safeTranslate
            }}
            <br />
            <span>{{
              loadedInspection.data?.differentMakeModelPassengerBackTire ||
              loadedInspection.data?.makeModelDriverBackTire
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentYearPassengerBackTire ||
              loadedInspection.data?.driverBackTireYear
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentTypePassengerBackTire ||
              loadedInspection.data?.typeDriverBackTire
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentSizePassengerBackTire ||
              loadedInspection.data?.sizeDriverBackTire
              }}</span>
          </p>
        </span>

        <!----------------------- 2nd Tire set  ------------------------------------------------------------->
        <span *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.tireSet2Available ?? ''
            )
          ">
          <h3 style="text-decoration: underline">
            {{
            "client.inspections.reports.inspection_details.tab_details.tires_2set"
            | safeTranslate
            }}
          </h3>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.tire1"
            | safeTranslate
            }}
            <br />
            <span>{{ loadedInspection.data?.makeModelTire1 }} </span>
            <span>{{ loadedInspection.data?.yearTire1 }} </span>
            <span>{{
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{ loadedInspection.data?.sizeTire1 }}</span>
          </p>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.tire2"
            | safeTranslate
            }}
            <br />
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameMakeModelTire2 ?? ''
                );
                else makeTire2
              ">{{ loadedInspection.data?.makeModelTire1 }}
            </span>
            <ng-template #makeTire2>
              <span>{{ loadedInspection.data?.differentMakeModelTire2 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameYearTire2 ?? ''
                );
                else yearTire2
              ">{{ loadedInspection.data?.yearTire1 }}
            </span>
            <ng-template #yearTire2>
              <span>{{ loadedInspection.data?.differentYearTire2 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameTypeTire2 ?? ''
                );
                else typeTire2
              ">
              {{
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <ng-template #typeTire2>
              <span>{{
                loadedInspection.data?.differentTypeTire2
                | safeTranslate: "inspection.comments."
                }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameSizeTire2 ?? ''
                );
                else sizeTire2
              ">{{ loadedInspection.data?.sizeTire1 }}
            </span>
            <ng-template #sizeTire2>
              <span>{{ loadedInspection.data?.differentSizeTire2 }} </span>
            </ng-template>
          </p>
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.tire3"
            | safeTranslate
            }}
            <br />
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameMakeModelTire3 ?? ''
                );
                else makeTire3
              ">{{ loadedInspection.data?.makeModelTire1 }}
            </span>
            <ng-template #makeTire3>
              <span>{{ loadedInspection.data?.differentMakeModelTire3 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameYearTire3 ?? ''
                );
                else yearTire3
              ">{{ loadedInspection.data?.yearTire1 }}
            </span>
            <ng-template #yearTire3>
              <span>{{ loadedInspection.data?.differentYearTire3 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameTypeTire3 ?? ''
                );
                else typeTire3
              ">
              {{
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <ng-template #typeTire3>
              <span>{{
                loadedInspection.data?.differentTypeTire3
                | safeTranslate: "inspection.comments."
                }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameSizeTire3 ?? ''
                );
                else sizeTire3
              ">{{ loadedInspection.data?.sizeTire1 }}
            </span>
            <ng-template #sizeTire3>
              <span>{{ loadedInspection.data?.differentSizeTire3 }} </span>
            </ng-template>
          </p>

          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.tire4"
            | safeTranslate
            }}
            <br />
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameMakeModelTire4 ?? ''
                );
                else makeTire4
              ">{{ loadedInspection.data?.makeModelTire1 }}
            </span>
            <ng-template #makeTire4>
              <span>{{ loadedInspection.data?.differentMakeModelTire4 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameYearTire4 ?? ''
                );
                else yearTire4
              ">{{ loadedInspection.data?.yearTire1 }}
            </span>
            <ng-template #yearTire4>
              <span>{{ loadedInspection.data?.differentYearTire4 }} </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameTypeTire4 ?? ''
                );
                else typeTire4
              ">
              {{
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <ng-template #typeTire4>
              <span>{{
                loadedInspection.data?.differentTypeTire4
                | safeTranslate: "inspection.comments."
                }}
              </span>
            </ng-template>
            <span *ngIf="
                ['Oui', 'Yes'].includes(
                  loadedInspection.data?.sameSizeTire4 ?? ''
                );
                else sizeTire3
              ">{{ loadedInspection.data?.sizeTire1 }}
            </span>
            <ng-template #sizeTire4>
              <span>{{ loadedInspection.data?.differentSizeTire4 }} </span>
            </ng-template>
          </p>

          <p *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            {{
            "client.inspections.reports.inspection_details.tab_details.tire5"
            | safeTranslate
            }}
            <br />
            <span>{{
              loadedInspection.data?.differentMakeModelTire5 ||
              loadedInspection.data?.makeModelTire1
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentYearTire5 ||
              loadedInspection.data?.yearTire1
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentTypeTire5 ||
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentSizeTire5 ||
              loadedInspection.data?.sizeTire1
              }}</span>
          </p>

          <p *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            {{
            "client.inspections.reports.inspection_details.tab_details.tire6"
            | safeTranslate
            }}
            <br />
            <span>{{
              loadedInspection.data?.differentMakeModelTire6 ||
              loadedInspection.data?.makeModelTire1
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentYearTire6 ||
              loadedInspection.data?.yearTire1
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentTypeTire6 ||
              loadedInspection.data?.typeTire1
              | safeTranslate: "inspection.comments."
              }}
            </span>
            <span>{{
              loadedInspection.data?.differentSizeTire6 ||
              loadedInspection.data?.sizeTire1
              }}</span>
          </p>
        </span>

        <span *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.tireSet2Available ?? ''
            )
          ">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.pre_mounted_tires"
            | safeTranslate
            }}
            <span>{{
              loadedInspection.data?.preMountedTires
              | safeTranslate: "inspection.comments."
              }}</span>
          </p>
        </span>
      </div>

      <div id="line" class="container-half-box-esthetics" style="border-left: 2px solid rgb(214, 214, 214); padding: 0">
      </div>

      <div id="interior_box" class="container-half-box-esthetics">
        <h3 *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.tireSet2Available ?? ''
            )
          " style="text-decoration: underline">
          {{
          "client.inspections.reports.inspection_details.tab_details.vehicle_tires"
          | safeTranslate
          }}
        </h3>
        <table id="default_table">
          <tr>
            <th style="width: 20%">
              {{
              "client.inspections.reports.inspection_details.tab_details.tires"
              | safeTranslate
              }}
            </th>
            <th id="mobile_comment">
              {{
              "client.inspections.reports.inspection_details.tab_details.comment"
              | safeTranslate
              }}
            </th>
            <th style="width: 20px">
              {{
              "client.inspections.reports.inspection_details.tab_details.tire_wear"
              | safeTranslate
              }}
            </th>
          </tr>
          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.front_driver_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.driverFrontTireWear,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeDriverFrontTire ?? ''
                    )
                      ? loadedInspection.data?.typeDriverBackTire
                      : loadedInspection.data?.differentTypeDriverFrontTire,
                    field: 'driverFront'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.driverFrontTireWear }}/32
            </td>
          </tr>

          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.front_passenger_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.passengerFrontTireWear,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypePassengerFrontTire ?? ''
                    )
                      ? loadedInspection.data?.typeDriverBackTire
                      : loadedInspection.data?.differentTypePassengerFrontTire,
                    field: 'passengerFront'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.passengerFrontTireWear }}/32
            </td>
          </tr>

          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.rear_driver_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.driverBackTireWear,
                    type: loadedInspection.data?.typeDriverBackTire,
                    field: 'driverBack'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.driverBackTireWear }}/32
            </td>
          </tr>
          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.rear_passenger_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.passengerBackTireWear,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypePassengerBackTire ?? ''
                    )
                      ? loadedInspection.data?.typeDriverBackTire
                      : loadedInspection.data?.differentTypePassengerBackTire,
                    field: 'passengerBack'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.passengerBackTireWear }}/32
            </td>
          </tr>

          <tr *ngIf="loadedInspection.data?.interiorDriverBackTireWear">
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.interior_rear_driver_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.interiorDriverBackTireWear,
                    type: loadedInspection.data?.typeDriverBackTire,
                    field: 'driverBack'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.interiorDriverBackTireWear }}/32
            </td>
          </tr>
          <tr *ngIf="loadedInspection.data?.interiorPassengerBackTireWear">
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.interior_rear_passenger_side"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.interiorPassengerBackTireWear,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypePassengerBackTire ?? ''
                    )
                      ? loadedInspection.data?.typeDriverBackTire
                      : loadedInspection.data?.differentTypePassengerBackTire,
                    field: 'passengerBack'
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.interiorPassengerBackTireWear }}/32
            </td>
          </tr>
        </table>

        <br />
        <table id="default_table" *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.tireSet2Available ?? ''
            )
          ">
          <h3 style="text-decoration: underline">
            {{
            "client.inspections.reports.inspection_details.tab_details.tires_2set"
            | safeTranslate
            }}
          </h3>
          <tr>
            <th style="width: 20%">
              {{
              "client.inspections.reports.inspection_details.tab_details.tires"
              | safeTranslate
              }}
            </th>
            <th id="mobile_comment">
              {{
              "client.inspections.reports.inspection_details.tab_details.comment"
              | safeTranslate
              }}
            </th>
            <th style="width: 20px">
              {{
              "client.inspections.reports.inspection_details.tab_details.tire_wear"
              | safeTranslate
              }}
            </th>
          </tr>
          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire1"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire1,
                    type: loadedInspection.data?.typeTire1
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire1 }}/32
            </td>
          </tr>

          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire2"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire2,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeTire2 ?? ''
                    )
                      ? loadedInspection.data?.typeTire1
                      : loadedInspection.data?.differentTypeTire2
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire2 }}/32
            </td>
          </tr>

          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire3"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire3,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeTire3 ?? ''
                    )
                      ? loadedInspection.data?.typeTire1
                      : loadedInspection.data?.differentTypeTire3
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire3 }}/32
            </td>
          </tr>
          <tr>
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire4"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire4,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeTire4 ?? ''
                    )
                      ? loadedInspection.data?.typeTire1
                      : loadedInspection.data?.differentTypeTire4
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire4 }}/32
            </td>
          </tr>
          <tr *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire5"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire5,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeTire5 ?? ''
                    )
                      ? loadedInspection.data?.typeTire1
                      : loadedInspection.data?.differentTypeTire5
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire5 }}/32
            </td>
          </tr>
          <tr *ngIf="
              ['6 roues', '6 wheels'].includes(
                loadedInspection.data?.numberOfTires ?? ''
              )
            ">
            <td>
              {{
              "client.inspections.reports.inspection_details.tab_details.tire6"
              | safeTranslate
              }}
            </td>
            <td id="mobile_comment">
              <ng-container *ngTemplateOutlet="
                  tireWearTemplate;
                  context: {
                    wear: loadedInspection.data?.tireWearTire6,
                    type: ['Oui', 'Yes'].includes(
                      loadedInspection.data?.sameTypeTire6 ?? ''
                    )
                      ? loadedInspection.data?.typeTire1
                      : loadedInspection.data?.differentTypeTire6
                  }
                ">
              </ng-container>
            </td>
            <td style="text-align: center">
              {{ loadedInspection.data?.tireWearTire6 }}/32
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<!------------------------------------------------------FONCTIONNEL----------------------------------------->
<div #fonctionnel class="container-full" *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div class="container-full-box">
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_details.functional"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <table id="default_table">
      <tr>
        <th style="width: 10%">
          {{
          "client.inspections.reports.inspection_details.tab_details.elements"
          | safeTranslate
          }}
        </th>
        <th id="mobile_comment">
          {{
          "client.inspections.reports.inspection_details.tab_details.comment"
          | safeTranslate
          }}
        </th>
        <th style="width: 20px">Condition</th>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.safety_belt"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.safetyBelt?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.safetyBelt?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.safetyBelt?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.front_seat_adjustment"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.frontSeatsSettings?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{ loadedInspection.data?.frontSeatsSettings?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontSeatsSettings?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.steeringWheelAdjustment">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.steeringWheelAdjustment"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment"></td>
        <ng-container [ngSwitch]="loadedInspection.data?.steeringWheelAdjustment">
          <td *ngSwitchCase="'Oui'" style="text-align: center">✔️</td>
          <td *ngSwitchCase="'Yes'" style="text-align: center">✔️</td>
          <td *ngSwitchCase="'Non'" style="text-align: center">❌</td>
          <td *ngSwitchCase="'No'" style="text-align: center">❌</td>
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.usbPort">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.usbPort"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.usbPort?.[1] == 'Non-fonctionnelle'"
            style="border: none; background: none">
            {{ loadedInspection.data?.usbPort?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.usbPort?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.Plug12v">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.Plug12v"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.Plug12v?.[1] == 'Non-fonctionnelle'"
            style="border: none; background: none">
            {{ loadedInspection.data?.Plug12v?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.Plug12v?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.audio_system"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.audioSystem?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.audioSystem?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.audioSystem?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.dashboard"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.onboardComputer?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{ loadedInspection.data?.onboardComputer?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.onboardComputer?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.instrumentCluster">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.instrument_cluster"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div
            *ngIf="loadedInspection.data?.instrumentCluster?.[0] != 'Excellent' && loadedInspection.data?.instrumentCluster?.[0] != 'N/A' "
            style="border: none; background: none">
            {{ loadedInspection.data?.instrumentCluster?.[1]}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.instrumentCluster?.[0]}">
        </ng-container>
      </tr>
      <!------- FrontWindshield   ------->
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.windshield"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <span *ngIf="loadedInspection.data?.frontWindshield?.[0] != 'Excellent'">
            {{ 'inspection.comments.' + loadedInspection.data?.frontWindshield?.[0] | safeTranslate }}
          </span>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontWindshield?.[0]}">
        </ng-container>
      </tr>
      <!------- BackWindshield   ------->
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.backwindshield"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <span *ngIf="loadedInspection.data?.backWindshield?.[0] != 'Excellent'">
            {{ 'inspection.comments.' + loadedInspection.data?.backWindshield?.[0] | safeTranslate }}
          </span>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.backWindshield?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.windshieldwiper_functional"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.WindshieldWiper;
              let i = index
            ">
            <span *ngIf="i != 0">
              {{ 'inspection.fields.' + loadedInspection.data?.body?.WindshieldWiper?.[i]?.[0] | safeTranslate

              }}:
              {{loadedInspection.data?.body?.WindshieldWiper?.[i]?.slice(2) | safeTranslate: 'inspection.comments.' }}
              <br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.WindshieldWiper?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.air_conditioning"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.airConditioning?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{ loadedInspection.data?.airConditioning?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.airConditioning?.[0]}">
        </ng-container>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.heatingSystem"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="!['Fonctionnel', 'Functional'].includes(loadedInspection.data?.heatingSystem?.[0] ?? '')"
            style="border: none; background: none">
            {{ loadedInspection.data?.heatingSystem?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.heatingSystem?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.electric_windows"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.electricWindows?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{ loadedInspection.data?.electricWindows?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.electricWindows?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.body?.Mirror">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.mirror_functional"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.Mirror;
              let i = index
            ">
            <span *ngIf="i != 0">
              {{ 'inspection.fields.' + loadedInspection.data?.body?.Mirror?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.Mirror?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.Mirror?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.glove_box"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.gloveCompartment?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{ loadedInspection.data?.gloveCompartment?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.gloveCompartment?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.trunk_opening"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.trunkOpening?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.trunkOpening?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.trunkOpening?.[0]}">
        </ng-container>
      </tr>
   
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.door_opening"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.DoorOpening;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.DoorOpening[i]?.[0]}} : {{loadedInspection.data?.body?.DoorOpening[i]?.[2]}},{{loadedInspection.data?.body?.DoorOpening[i]?.[3]}}&nbsp; 
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.DoorOpening?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.DoorOpening?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.DoorOpening?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.carHorn">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.horn"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.carHorn?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.carHorn?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.carHorn?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.doorLocking">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.door_locking"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.doorLocking?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.doorLocking?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.doorLocking?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.fuelDoor && loadedInspection.data?.fuelDoor?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.fuel_door"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.fuelDoor?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.fuelDoor?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.fuelDoor?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.chargeDoor && loadedInspection.data?.chargeDoor?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.charge_door"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.chargeDoor?.[0] != 'Excellent'" style="border: none; background: none">
            {{ loadedInspection.data?.chargeDoor?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.chargeDoor?.[0]}">
        </ng-container>
      </tr>
    </table>

    <!-- Not needed as of 2022-07-12 (no isOtherOptions field exists). Kept for support. -->
    <span *ngIf="
        loadedInspection.data?.isOtherOptions &&
        !['Non', 'No'].includes(loadedInspection.data?.isOtherOptions ?? '')
      ">
      <p>
        {{
        "client.inspections.reports.inspection_details.tab_details.additional_options"
        | safeTranslate
        }}<span>{{
          removeStateValues(loadedInspection.data?.otherOptions ?? "")
          | safeTranslate: "inspection.comments."
          }}</span>
      </p>
    </span>

    <span *ngIf="
        !loadedInspection.data?.isOtherOptions &&
        loadedInspection.data?.otherOptions
      ">
      <p>
        {{
        "client.inspections.reports.inspection_details.tab_details.additional_options"
        | safeTranslate
        }}
        <span>{{
          removeStateValues(loadedInspection.data?.otherOptions ?? "")
          | safeTranslate: "inspection.comments."
          }}</span>
        <span *ngIf="
            loadedInspection.data?.otherOptions?.includes('Autre') ||
            loadedInspection.data?.otherOptions?.includes('Other')
          ">
          :
          {{
          loadedInspection.data?.otherOptionsOther
          | safeTranslate: "inspection.comments."
          }}
        </span>
      </p>
    </span>
  </div>
</div>

<!------------------------------------------------------EQUIPMENTS----------------------------------------->
<div #equipment  class="container-full" *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div class="container-full-box">
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_details.equipment"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <div class="container-half-esthetics" style="border: none">
      <div class="container-half-box-esthetics">
        <p *ngIf="loadedInspection.data?.keysNumber">
          {{
          "client.inspections.reports.inspection_details.tab_details.keys"
          | safeTranslate
          }}<span>{{ loadedInspection.data?.keysNumber }}</span>
        </p>

        <p *ngIf="loadedInspection.data?.carRemoteOpening">
          {{
          "client.inspections.reports.inspection_details.tab_details.remote_opening"
          | safeTranslate
          }}
          <span *ngIf="loadedInspection.data?.carRemoteOpening?.[0]=='Mauvais' else RemoteOpeningMsg ">
            {{loadedInspection.data?.carRemoteOpening?.[1]}}
          </span>
          <ng-template #RemoteOpeningMsg>
            <span>
              {{loadedInspection.data?.carRemoteOpening?.[0] | safeTranslate:'inspection.comments.'}}
            </span>
          </ng-template>
        </p>
        <p *ngIf="loadedInspection.data?.trunkEquipment">
          {{
          "client.inspections.reports.inspection_details.tab_details.trunk_equipment"
          | safeTranslate
          }}<span>{{
            loadedInspection.data?.trunkEquipment
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>

        <!-- Not needed as of 2022-07-12 (no isAdditionalEquipment field exists). Kept for support. -->
        <span *ngIf="
            ['Oui', 'Yes'].includes(
              loadedInspection.data?.isAdditionalEquipment ?? ''
            )
          ">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.additional_equipment"
            | safeTranslate
            }}<span>{{ loadedInspection.data?.additionalEquipment }}</span>
          </p>
        </span>
        <span *ngIf="
            ['Non', 'No'].includes(
              loadedInspection.data?.isAdditionalEquipment ?? ''
            )
          ">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.additional_equipment"
            | safeTranslate
            }}<span>{{
              "client.inspections.reports.inspection_details.tab_details.none"
              | safeTranslate
              }}</span>
          </p>
        </span>

        <span *ngIf="
            !loadedInspection.data?.isAdditionalEquipment &&
            loadedInspection.data?.additionalEquipment
          ">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.additional_equipment"
            | safeTranslate
            }}
            <span>{{
              removeStateValues(
              loadedInspection.data?.additionalEquipment ?? ""
              ) | safeTranslate: "inspection.comments."
              }}</span>
            <span *ngIf="
                loadedInspection.data?.additionalEquipment?.includes('Autre') ||
                loadedInspection.data?.additionalEquipment?.includes('Other')
              ">
              :
              {{
              loadedInspection.data?.additionalEquipmentOther
              | safeTranslate: "inspection.comments."
              }}
            </span>
          </p>
        </span>
      </div>

      <div id="line" class="container-half-box-esthetics" style="border-left: 2px solid rgb(214, 214, 214); padding: 0">
      </div>

      <div id="interior_box" class="container-half-box-esthetics">
        <p>
          {{
          "client.inspections.reports.inspection_details.tab_details.floor_mat"
          | safeTranslate
          }}<span>{{
            loadedInspection.data?.floorMatsInclude
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>
        <span *ngIf="loadedInspection.data?.floorMatsInclude?.includes('Été')">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.floor_mat_summer"
            | safeTranslate
            }}<span>{{
              loadedInspection.data?.summerFloorMats
              | safeTranslate: "inspection.comments."
              }}</span>
          </p>
        </span>

        <span *ngIf="loadedInspection.data?.floorMatsInclude?.includes('Hiver')">
          <p>
            {{
            "client.inspections.reports.inspection_details.tab_details.floor_mat_winter"
            | safeTranslate
            }}<span>{{
              loadedInspection.data?.winterFloorMats
              | safeTranslate: "inspection.comments."
              }}</span>
          </p>
        </span>

        <p>
          {{
          "client.inspections.reports.inspection_details.tab_details.manual"
          | safeTranslate
          }}<span>{{
            loadedInspection.data?.isManualInclude
            | safeTranslate: "inspection.comments."
            }}</span>
        </p>

        <p>
          {{
          "client.inspections.reports.inspection_details.tab_details.maintenance_invoices"
          | safeTranslate
          }}
          <span>
            {{
            loadedInspection.data?.maintenanceInvoices
            | safeTranslate: "inspection.comments."
            }}
          </span>
        </p>
      </div>
    </div>
  </div>
</div>

<!------------------------------------------------------------ESTHÉTIQUE--------------------------------->
<div  class="container-full" *ngIf="inspection$ | async as loadedInspection; else loadingTemplate">
  <div  class="esthetique">
    <div #esthetique >
    <h2>
      {{
      "client.inspections.reports.inspection_details.tab_details.esthetic"
      | safeTranslate
      }}
    </h2>
    <hr class="solid" />
    <h3>
      {{
      "client.inspections.reports.inspection_details.tab_details.interior"
      | safeTranslate
      }}
    </h3>
    <table id="default_table">
      <tr>
        <th style="width: 10%">
          {{
          "client.inspections.reports.inspection_details.tab_details.elements"
          | safeTranslate
          }}
        </th>
        <th id="mobile_comment">
          {{
          "client.inspections.reports.inspection_details.tab_details.comment"
          | safeTranslate
          }}
        </th>
        <th style="width: 20px">Condition</th>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.cleanliness"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.overallCleanliness?.[0] != 'Excellent'"
            style="border: none; background: none">
            {{loadedInspection.data?.overallCleanliness?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.overallCleanliness?.[0]}">
        </ng-container>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.odor"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="let comment of loadedInspection.data?.odor; let i = index">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.odor?.[0] != 'Excellent'" style="border: none; background: none">
                {{loadedInspection.data?.odor?.[i] | safeTranslate: 'inspection.comments.'

                }}<br />
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.odor?.[0]}">
        </ng-container>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.roof_stains"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.headliner?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.headliner?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.headliner?.[0]}">
        </ng-container>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.seat_stains_front"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.frontSeats?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.frontSeats?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.frontSeats?.[0]}">
        </ng-container>
      </tr>
      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.seat_stains_back"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.backSeats?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.backSeats?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.backSeats?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.carpets">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.carpets"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div
            *ngIf="loadedInspection.data?.carpets?.[0] != 'Excellent' && loadedInspection.data?.carpets?.[0] != 'N/A'"
            style="border: none; background: none">
            {{loadedInspection.data?.carpets?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.carpets?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.trunk_stains"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.insideTrunk?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.insideTrunk?.[1] | safeTranslate: 'inspection.comments.'}}
            {{
            "client.inspections.reports.inspection_details.tab_details.stains"
            | safeTranslate
            }}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.insideTrunk?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="
          loadedInspection.data?.cargoCover &&
          loadedInspection.data?.cargoCoverFunctional
        ">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.cargoCover"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment"></td>

        <td *ngIf="
            ['Yes', 'Oui'].includes(loadedInspection.data?.cargoCover ?? '') &&
            ['Yes', 'Oui'].includes(
              loadedInspection.data?.cargoCoverFunctional ?? ''
            )
          " style="text-align: center">
          ✔️
        </td>
        <td *ngIf="
            ['No', 'Non'].includes(loadedInspection.data?.cargoCover ?? '') ||
            ['No', 'Non'].includes(
              loadedInspection.data?.cargoCoverFunctional ?? ''
            )
          " style="text-align: center">
          ❌
        </td>
      </tr>

      <!-- carPlastics -> oldField, but kept to support old version 
        carPlastics separated in 3 fields : doorPlastics, dashboardPlastics and centralConsolePlastics
        (since 03/2022)
      -->
      <tr *ngIf="
          loadedInspection.data?.carPlastics &&
          !loadedInspection.data?.doorPlastics
        ">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.plastics_trim"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.carPlastics;
              let i = index
            ">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.carPlastics?.[0] != 'Excellent'"
                style="border: none; background: none">
                {{loadedInspection.data?.carPlastics?.[i] | safeTranslate: 'inspection.comments.'}}
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.carPlastics?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.doorPlastics">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.doors_plastics"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.doorPlastics;
              let i = index
            ">
            <span *ngIf="i != 0">
              <div
                *ngIf="loadedInspection.data?.doorPlastics?.[0] != 'Excellent' && loadedInspection.data?.doorPlastics?.[1] != 'Autre'"
                style="border: none; background: none">
                {{loadedInspection.data?.doorPlastics?.[i] | safeTranslate: 'inspection.comments.'}}
              </div>
              <div
                *ngIf="loadedInspection.data?.doorPlastics?.[0] != 'Excellent' && loadedInspection.data?.doorPlastics?.[1] == 'Autre'"
                style="border: none; background: none">
                {{loadedInspection.data?.doorPlastics?.[2] | safeTranslate: 'inspection.comments.'}}
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.doorPlastics?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.dashboardPlastics">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.dashboard_plastics"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div
            *ngIf="loadedInspection.data?.dashboardPlastics?.[0] != 'Excellent' && loadedInspection.data?.dashboardPlastics?.[1] != 'Autre'"
            style="border: none; background: none">
            {{loadedInspection.data?.dashboardPlastics?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
          <div
            *ngIf="loadedInspection.data?.dashboardPlastics?.[0] != 'Excellent' && loadedInspection.data?.dashboardPlastics?.[1] == 'Autre'"
            style="border: none; background: none">
            {{loadedInspection.data?.dashboardPlastics?.[2] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.dashboardPlastics?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.centralConsolePlastics">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.central_console_plastics"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div
            *ngIf="loadedInspection.data?.centralConsolePlastics?.[0] != 'Excellent' && loadedInspection.data?.centralConsolePlastics?.[1] != 'Autre'"
            style="border: none; background: none">
            {{loadedInspection.data?.centralConsolePlastics?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
          <div
            *ngIf="loadedInspection.data?.centralConsolePlastics?.[0] != 'Excellent' && loadedInspection.data?.centralConsolePlastics?.[1] == 'Autre'"
            style="border: none; background: none">
            {{loadedInspection.data?.centralConsolePlastics?.[2] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.centralConsolePlastics?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.sunVisor">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.sunVisor"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <div *ngIf="loadedInspection.data?.sunVisor?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.sunVisor?.[1] | safeTranslate: 'inspection.comments.'}}
          </div>
          <div *ngIf="loadedInspection.data?.sunVisor?.[0] != 'Excellent'" style="border: none; background: none">
            {{loadedInspection.data?.sunVisor?.[2] | safeTranslate: 'inspection.comments.'}}
          </div>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.sunVisor?.[0]}">
        </ng-container>
      </tr>
    </table>
    </div>
    <div #exterior>

    <h3>
      {{
      "client.inspections.reports.inspection_details.tab_details.exterior"
      | safeTranslate
      }}
    </h3>
    <table id="default_table">
      <tr>
        <th style="width: 10%">
          {{
          "client.inspections.reports.inspection_details.tab_details.elements"
          | safeTranslate
          }}
        </th>
        <th id="mobile_comment">
          {{
          "client.inspections.reports.inspection_details.tab_details.comment"
          | safeTranslate
          }}
        </th>
        <th style="width: 20px">Condition</th>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.bumper"
          | safeTranslate
          }}
        </td>
        <!--
          <td id="mobile_comment">{{loadedInspection.data?.body?.Bumper[1]?.[0]}} : {{loadedInspection.data?.body?.Bumper[1]?.[2]}}</td>
          -->
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.Bumper;
              let i = index
            ">
            <span *ngIf="i != 0">
              {{ 'inspection.fields.' + loadedInspection.data?.body?.Bumper?.[i]?.[0] | safeTranslate

              }}:
              {{loadedInspection.data?.body?.Bumper?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.Bumper?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.windshieldwiper"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body
                ?.WindshieldWiperEsthetic;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.WindshieldWiperEsthetic[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.WindshieldWiperEsthetic[i])}}&nbsp;
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.WindshieldWiperEsthetic?.[i]?.[0] | safeTranslate

              }}:
              {{loadedInspection.data?.body?.WindshieldWiperEsthetic?.[i]?.slice(2) | safeTranslate:
              'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.WindshieldWiperEsthetic?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.optical_block"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.OpticalBlock;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.OpticalBlock[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.OpticalBlock[i])}}&nbsp;
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.OpticalBlock?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.OpticalBlock?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.OpticalBlock?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.mirror"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.MirrorEsthetic;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.MirrorEsthetic[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.MirrorEsthetic[i])}}&nbsp;
              -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.MirrorEsthetic?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.MirrorEsthetic?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.MirrorEsthetic?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.wing"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.Wing;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.Wing[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.Wing[i])}}&nbsp;
              -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.Wing?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.Wing?.[i]?.slice(2) | safeTranslate: 'inspection.comments.'

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.Wing?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.door"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.Door;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.Door[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.Door[i])}}&nbsp;
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.Door?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.Door?.[i]?.slice(2) | safeTranslate: "inspection.comments."

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.Door?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.lower_body"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.UnderBody;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.UnderBody[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.UnderBody[i])}}&nbsp;
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.UnderBody?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.UnderBody?.[i]?.slice(2) | safeTranslate: "inspection.comments."

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container
          *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.UnderBody?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.rim"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.body?.Rim;
              let i = index
            ">
            <span *ngIf="i != 0">
              <!--
                {{loadedInspection.data?.body?.Rim[i]?.[0]}} : {{translateArray(loadedInspection.data?.body?.Rim[i])}}&nbsp;
                -->
              {{ 'inspection.fields.' + loadedInspection.data?.body?.Rim?.[i]?.[0] | safeTranslate

              }}:
              {{
              loadedInspection.data?.body?.Rim?.[i]?.slice(2) | safeTranslate: "inspection.comments."

              }}<br />
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.body?.Rim?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.hood"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="let comment of loadedInspection.data?.hood; let i = index">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.hood?.[0] != 'Excellent'" style="border: none; background: none">
                {{ loadedInspection.data?.hood?.[i] | safeTranslate: 'inspection.comments.'

                }}<br />
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.hood?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.trunk"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="let comment of loadedInspection.data?.trunk; let i = index">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.trunk?.[0] != 'Excellent'" style="border: none; background: none">
                {{ loadedInspection.data?.trunk?.[i] | safeTranslate: 'inspection.comments.'

                }}<br />
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.trunk?.[0]}">
        </ng-container>
      </tr>

      <tr>
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.roof"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="let comment of loadedInspection.data?.roof; let i = index">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.roof?.[0] != 'Excellent'" style="border: none; background: none">
                {{ loadedInspection.data?.roof?.[i] | safeTranslate: 'inspection.comments.'

                }}<br />
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.roof?.[0]}">
        </ng-container>
      </tr>

      <tr *ngIf="loadedInspection.data?.roofOpening">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.roof_opening"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <ng-container *ngFor="
              let comment of loadedInspection.data?.roofOpening;
              let i = index
            ">
            <span *ngIf="i != 0">
              <div *ngIf="loadedInspection.data?.roofOpening?.[0] != 'Excellent'"
                style="border: none; background: none">
                {{ loadedInspection.data?.roofOpening?.[i] | safeTranslate: 'inspection.comments.'

                }}<br />
              </div>
            </span>
          </ng-container>
        </td>
        <ng-container *ngTemplateOutlet="indicatorTemplate; context: {state: loadedInspection.data?.roofOpening?.[0]}">
        </ng-container>
      </tr>

      <!------- car's Floor (trunkLiner is included) -->
      <tr [ngSwitch]="loadedInspection.data?.floor?.[0] ??  '' + loadedInspection.data?.trunkLiner?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.floor"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <span style="border: 0"
            *ngIf="loadedInspection.data?.floor?.[0] != 'Excellent' || loadedInspection.data?.trunkLiner?.[0] != 'Excellent' ">
            <span *ngIf="loadedInspection.data?.floor?.[0] != 'Excellent'" style="border: none; background: none">
              {{ loadedInspection.data?.floor?.[1] | safeTranslate: 'inspection.comments.'

              }},
            </span>

            <span *ngIf="loadedInspection.data?.trunkLiner?.[0] != 'Excellent'" style="border: none; background: none">
              {{loadedInspection.data?.trunkLiner?.[1] | safeTranslate: 'inspection.comments.'}}
              {{
              "client.inspections.reports.inspection_details.tab_details.trunk_rust"
              | safeTranslate
              }}
            </span>
          </span>
        </td>
        <td *ngSwitchCase="'Excellent'" style="text-align: center">✔️</td>
        <td *ngSwitchCase="'Bon'" style="text-align: center">💬</td>
        <td *ngSwitchCase="'Good'" style="text-align: center">💬</td>
        <td *ngSwitchDefault style="text-align: center">❌</td>
        <td *ngSwitchCase="'N/A'" style="text-align: center">N/A</td>
      </tr>

      <tr *ngIf="loadedInspection.data?.paint" [ngSwitch]="loadedInspection.data?.paint?.[0]">
        <td>
          {{
          "client.inspections.reports.inspection_details.tab_details.paint"
          | safeTranslate
          }}
        </td>
        <td id="mobile_comment">
          <span *ngIf="['Oui', 'Yes'].includes(loadedInspection.data?.paint?.[0] ?? '')">
            {{ loadedInspection.data?.paint?.[1]}}
          </span>
        </td>
        <td *ngSwitchCase="'Excellent'" style="text-align: center">✔️</td>
        <td *ngSwitchCase="'Non'" style="text-align: center">✔️</td>
        <td *ngSwitchCase="'No'" style="text-align: center">✔️</td>
        <td *ngSwitchCase="'Bon'" style="text-align: center">💬</td>
        <td *ngSwitchCase="'Good'" style="text-align: center">💬</td>
        <td *ngSwitchCase="'Oui'" style="text-align: center">💬</td>
        <td *ngSwitchCase="'Yes'" style="text-align: center">💬</td>
        <td *ngSwitchCase="'Mauvais'" style="text-align: center">❌</td>
        <td *ngSwitchCase="'Bad'" style="text-align: center">❌</td>
        <td *ngSwitchCase="'N/A'" style="text-align: center">N/A</td>
      </tr>
    </table>
  </div>
    <p *ngIf="loadedInspection.data?.rustInhibitor">
      {{
      "client.inspections.reports.inspection_details.tab_details.rust_inhibitor"
      | safeTranslate
      }}
      <span>
        {{
        loadedInspection.data?.rustInhibitor
        | safeTranslate: "inspection.comments."
        }}
      </span>
    </p>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading-secondary></app-loading-secondary>
</ng-template>

<ng-template #tireWearTemplate let-wear="wear" let-type="type" let-field="field">
  <span *ngIf="logic.isTireTooOld(field, inspection$.value ?? undefined); else TireStillYoung">
    {{ "replacementRequiredTooOld" | safeTranslate: "inspection.comments." }}
  </span>
  <ng-template #TireStillYoung>
    <span *ngIf="int(wear) >= 8">
      {{ "inspection.comments.nothingToPlan" | safeTranslate }}
    </span>
    <span *ngIf="wear < 8">
      {{
      logic.tireWearMessageDisplayed(wear, type)
      | safeTranslate: "inspection.comments."
      }}
    </span>
  </ng-template>
</ng-template>

<ng-template #roadTestCommentTemplate let-field="field">
  <td id="mobile_comment" [class.warning]="inspection$.value?.data?.[field + 'DamageLevel'] === 'Major' ||
      inspection$.value?.data?.[field + 'DamageLevel'] === 'Majeur'">
    <ng-container *ngIf="inspection$.value?.data?.[field]?.[0] !== 'Excellent'">
      <span
        *ngIf="inspection$.value?.data?.[field]?.[0] !== 'Mauvais' && inspection$.value?.data?.[field]?.[0] !== 'Bon'">{{
        inspection$.value?.data?.[field]?.[0] | safeTranslate: 'inspection.comments.' }}
        :
      </span>
      <span *ngIf="field !== 'brakeNoice'">
        {{ inspection$.value?.data?.[field].slice(1).join(', ') | safeTranslate:'inspection.comments.' }}
      </span>
      <span *ngIf="inspection$.value?.data?.[field + 'DamageFrontBack']">
        {{ inspection$.value?.data?.[field + 'DamageFrontBack'].join(' ')?.toLowerCase() | safeTranslate: 'inspection.comments.' }}
      </span>
      <span *ngIf="inspection$.value?.data?.[field + 'DamageLeftRight']">
        {{ inspection$.value?.data?.[field + 'DamageLeftRight']?.toLowerCase() | safeTranslate: 'inspection.comments.' }}
      </span>
      <span *ngIf="inspection$.value?.data?.[field + 'DamageLevel']">
        ({{ inspection$.value?.data?.[field + 'DamageLevel']?.toLowerCase() | safeTranslate: 'inspection.comments.' }})
      </span>
    </ng-container>
  </td>
</ng-template>

<ng-template #leakCommentTemplate let-field="field">
  <td id="mobile_comment" [class.warning]="inspection$.value?.data?.[field + 'DamageLevel'] === 'Major' ||
      inspection$.value?.data?.[field + 'DamageLevel'] === 'Majeur'">
    <ng-container *ngIf="inspection$.value?.data?.[field]?.[0] !== 'No'">
      <span
        *ngIf="inspection$.value?.data?.[field][1] !== 'Recommandation personnalisée' &&
                inspection$.value?.data?.[field][1] !== 'Personalized recommendation'; else personalizedCommentTemplate">
        {{ inspection$.value?.data?.[field].slice(1).join(', ') | safeTranslate: 'inspection.comments.' }}
      </span>
      <ng-template #personalizedCommentTemplate>
        {{ inspection$.value?.data?.recommandations?.[field] | safeTranslate: 'inspection.comments.' }}
      </ng-template>
    </ng-container>
  </td>
</ng-template>

<ng-template #electricalConnectorTemplate let-field="field">
  <div class="container-half-esthetics electrical-connector-container">
    <ng-container
      *ngIf="['J1772', 'CHAdeMO', 'SAEComboCCS', 'Tesla'].includes(inspection$.value?.data?.[field + 'Type']); else otherConnectorType">
      <img src="/assets/electrical-connector-types/{{inspection$.value?.data?.[field + 'Type']}}.png" alt=""
        class="connectorPhoto">
    </ng-container>
    <ng-template #otherConnectorType>
          <img src="{{inspection$.value?.data?.[field + 'TypePhoto']?.url}}" alt="" class="connectorPhoto">
    </ng-template>
    <p>Type: <span>{{ inspection$.value?.data?.[field + 'Type'] | safeTranslate:
        'client.inspections.reports.inspection_details.tab_synthesis.connectorType.' }}</span></p>
    <p>
      Condition: <span>{{ inspection$.value?.data?.[field][0] }}
        <span *ngIf="inspection$.value?.data?.[field][0] !== 'Excellent' &&
                inspection$.value?.data?.[field][1]"> - {{inspection$.value?.data?.[field][1]}}</span></span>
    </p>
  </div>
</ng-template>

<ng-template #indicatorTemplate let-state="state">
  <ng-container [ngSwitch]="state">
    <td *ngSwitchCase="'Excellent'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Bon'" style="text-align: center">💬</td>
    <td *ngSwitchCase="'Good'" style="text-align: center">💬</td>
    <td *ngSwitchCase="'Mauvais'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Bad'" style="text-align: center">❌</td>

    <td *ngSwitchCase="'Non'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'No'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Oui'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Yes'" style="text-align: center">❌</td>

    <td *ngSwitchCase="'Fonctionnelle'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Functional'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Non-fonctionnelle'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Non-Fonctionnel'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Nonfunctional'" style="text-align: center">❌</td>
    <!-- vehicleMotricityType -->
    <td *ngSwitchCase="'Fonctionnelles'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Functionals'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Non-fonctionnelles'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Nonfunctionals'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Permanente'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'Permanent'" style="text-align: center">✔️</td>

    <td *ngSwitchCase="'Impact(s)'" style="text-align: center">💬</td>
    <td *ngSwitchCase="'Fissuré'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Cracked'" style="text-align: center">❌</td>

    <!-- For batteryCondition (old battery name) -->
    <td *ngSwitchCase="'Rouille'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Rust'" style="text-align: center">❌</td>
    <!-- Fissure equals Cracked which is already defined above -->
    <td *ngSwitchCase="'Fissure'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Fissure(s)'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Déformation'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Deformed'" style="text-align: center">❌</td>

    <!-- isOBDScanner -->
    <td *ngSwitchCase="'Aucun'" style="text-align: center">✔️</td>
    <td *ngSwitchCase="'None'" style="text-align: center">✔️</td>
    <!-- airFilter -->
    <td *ngSwitchCase="'À changer'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Replacement'" style="text-align: center">❌</td>

    <!-- airFilter, homeChargerWorking, and electricalConnector -->
    <td *ngSwitchCase="'Non vérifiable'" style="text-align: center">💬</td>
    <td *ngSwitchCase="'Not verifiable'" style="text-align: center">💬</td>

    <!-- Road test fields -->
    <td *ngSwitchCase="'Bruit'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Noise'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Vibration'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Jeu'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Play'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Point dur'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Hard to turn'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Dérive'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Drift'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Autre'" style="text-align: center">❌</td>
    <td *ngSwitchCase="'Other'" style="text-align: center">❌</td>

    <td *ngSwitchCase="'N/A'" style="text-align: center">N/A</td>
    <td *ngSwitchCase="'Non vérifié(e)'" style="text-align: center">
      {{
      "client.inspections.reports.inspection_details.tab_details.not_verified"
      | safeTranslate
      }}
    </td>
    <td *ngSwitchCase="'Not verified'" style="text-align: center">
      {{
      "client.inspections.reports.inspection_details.tab_details.not_verified"
      | safeTranslate
      }}
    </td>
  </ng-container>
</ng-template>
