import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeTranslate } from './safe-translate.pipe';
import { SharedModule } from '../shared/shared.module';
import { simpleTranslate } from './simple-translate.pipe';
import { TranslateWithLinkPipe } from './translate-with-link';

const pipes = [
  SafeHtmlPipe,
  SafeUrlPipe,
  TranslateWithLinkPipe,
  SafeTranslate,
  simpleTranslate,
];

@NgModule({
  declarations: pipes,
  imports: [
    CommonModule,
  ],
  exports: pipes
})
export class PipesModule { }
