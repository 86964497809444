import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-new-inspection-type',
  templateUrl: './modal-new-inspection-type.component.html',
  styleUrls: ['./modal-new-inspection-type.component.scss']
})
export class ModalNewInspectionTypeComponent {

  constructor(public dialogRef: MatDialogRef<ModalNewInspectionTypeComponent>) { }

  _closeModal(confirmed?: string) {
    this.dialogRef.close(confirmed);
  }
}
