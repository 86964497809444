import { Component, OnInit } from '@angular/core';
import { reload, updateProfile, User } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit {

  firstName!: string;
  lastName!: string;

  get displayName() {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(private auth: AngularFireAuth, public dialogRef: MatDialogRef<ProfileUpdateComponent>) { }

  ngOnInit() {
  }

  canUpdate(): boolean {
    if (this.firstName == null || this.lastName == null || this.firstName == '' || this.lastName == '') {
      return false;
    }

    return true;
  }

  async updateProfile() {

    if (!this.canUpdate()) {
      return;
    }

    let user = await this.auth.currentUser;
    if (user) {
      await updateProfile(user, {displayName: this.displayName}).then(async () => {
        let user = await this.auth.currentUser;
        if (user) reload(user);
        this.dialogRef.close();
      });
    }
  }
}
