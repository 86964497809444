import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Graphics } from 'src/app/entities/graphics';

@Component({
  selector: 'app-authentication-graphics',
  templateUrl: './authentication-graphics.component.html',
  styleUrls: ['./authentication-graphics.component.scss']
})
export class AuthenticationGraphicsComponent implements OnInit {

  @Input() alternateGraphics!: Graphics;
  @Input() close : boolean = false;

  
  isLoading: boolean = true;

  constructor(
    public translate:TranslateService,
    public router:Router
  ) { 
    this.translate.currentLang = this.translate.currentLang;
  }
  ngOnInit() {
    this.isLoading = false;
  }

}
