import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-choice',
  templateUrl: './form-choice.component.html',
  styleUrls: ['./form-choice.component.scss']
})
export class FormChoiceComponent {

  @Input() choice: any;
  @Input() selected: boolean = false;
  @Input() noHover: boolean = false;

  constructor() { }
}
