import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'simpleTranslate'
})
export class simpleTranslate implements PipeTransform {

  constructor(private translate: TranslateService) {}
  transform(comment: string) {
    const translatedComment = this.translate.instant(comment);
    return translatedComment === comment ? comment : translatedComment;
  }

  /*
  public translateComment(comment: string) {
    const translatedComment = this.translate.instant(comment);
    return translatedComment === comment ? comment : translatedComment;
  }
*/
  /*
  public translateArray(values: string[]) {
    return values.slice(2).map(value => this.translateComment(value)).join(', ');
  }
  */
}