import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  settingsUrl = environment.apiUrl + '/client/settings';

  constructor(private auth: AuthService, private http: HttpClient) { }

  async getHelpUrl(): Promise<string | undefined> {
    const token = await this.auth.token();

    if (token === null) {
      return undefined;
    }

    const url = this.settingsUrl + "/help/";
    return firstValueFrom(this.http.get<string>(url)).catch(error => {
      throw new Error(error);
    });
  }
}
