import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CompleteInspection } from 'src/app/entities/inspections/complete-inspection';
import { InspectionDisplayLogicService } from 'src/app/services/inspection-display-logic.service';

@Component({
  selector: 'app-inspection-report',
  templateUrl: './inspection-report.component.html',
  styleUrls: ['./inspection-report.component.scss']
})
export class InspectionReportComponent {

  @Input() authorizedAccess!: boolean;
  @Input() inspection$ = new BehaviorSubject<CompleteInspection | null>(null);
  @ViewChild('information') information! : ElementRef ;
  @ViewChild('essaiRoutier') essaiRoutier! : ElementRef ;
  @ViewChild('mecanique') mecanique!:ElementRef;
  @ViewChild('lumier') lumier!:ElementRef;
  @ViewChild('wheels') wheels!:ElementRef;
  @ViewChild('fonctionnel') fonctionnel!:ElementRef;
  @ViewChild('equipment') equipment!:ElementRef;
  @ViewChild('esthetique') esthetique!:ElementRef;
  @ViewChild('exterior') exterior!:ElementRef;

  // For html access
  arrayObject = Array;
  int = (value: number) => value;

  constructor(
    public logic: InspectionDisplayLogicService,
    private translate: TranslateService,
  ) {
    
   }

  public translateComment(comment: string) {
    const translatedComment = this.translate.instant(comment);
    return translatedComment === comment ? comment : translatedComment;
  }

  public translateArray(values: string[]) {
    return values.slice(2).map(value => this.translateComment(value)).join(', ');
  }

  findWorstRoadTestState(states: (string | undefined)[]): string {
    if (!states[0] || !states[1]) return '';

    switch(states[0]) {
      case 'Non vérifié(e)':
      case 'Validated':
      case 'Not verified':
        return states[1];
    }
    
    return states[0];
  }

  removeStateValues(elements: string | string[]) {
    if (typeof elements === 'string') return elements; // Kept for older version support 07-25-2022
    return elements.filter(e => e !== 'Excellent' && e !== 'Bon' && e !== 'Mauvais');
  }
  
  getHTMLSectionsClones(){
    return [
      this.information?.nativeElement.cloneNode(true),
      this.essaiRoutier?.nativeElement.cloneNode(true),
      this.mecanique?.nativeElement.cloneNode(true),
      this.lumier?.nativeElement.cloneNode(true),
      this.wheels?.nativeElement.cloneNode(true),
      this.fonctionnel?.nativeElement.cloneNode(true),
      this.equipment?.nativeElement.cloneNode(true),
      this.esthetique?.nativeElement.cloneNode(true),
      this.exterior?.nativeElement.cloneNode(true),
    ].filter(e => !!e);
  }

  getVisibleShockAbsorbersLeakConditions(leakCondition: string | string[]) {
    if (typeof leakCondition === 'string') return leakCondition; // Kept for older version support 07-25-2022
    return leakCondition.join(', ');
  }
}
