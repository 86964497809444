import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-vehicle-details',
  templateUrl: './modal-vehicle-details.component.html',
  styleUrls: ['./modal-vehicle-details.component.scss']
})
export class ModalVehicleDetailsComponent implements OnInit {

  public isVehicleLiked: boolean = this.data.isVehicleLiked;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vehicle: string, isVehicleLiked: boolean },
    public dialogRef: MatDialogRef<ModalVehicleDetailsComponent>
  ) { }

  ngOnInit() { }

  public handleVehiculeEvaluation(isVehicleLiked: boolean) {
    this.isVehicleLiked = isVehicleLiked;
    this.dialogRef.close(this.isVehicleLiked);
    // this._closeModal();
  }

  public _closeModal() {
    this.dialogRef.close();
  }

}
