<div class="user" *ngIf="user">
    <img *ngIf="user.photoURL; else placeholder" [src]="user.photoURL" referrerpolicy="no-referrer">
    <ng-template #placeholder>
       <div class="dropdown">
        <button class="placeholder" [ngStyle]="{'background-color': getColor()}" routerLink="/client/profile">
            {{ getUserInitials(user) }}
        <!--
            <div class="dropdown-content">
                <a  routerLink="/client/profile">{{ 'client.homepage.tiles.profile.my_profile' | translate }}</a>
                <a (click)="disconnectUser()">{{ 'general.logout' | translate }}</a>
            </div>
        -->
         </button>
        </div>
    </ng-template>
</div>